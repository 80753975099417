import {motion} from 'framer-motion/dist/framer-motion'
import React from 'react';
import styled from 'styled-components';
import ClubEventDate from './ClubEventDate';
import ClubEventDj from './ClubEventDj';
import ClubEventName from './ClubEventName';
import ClubEventSeparator from './ClubEventSeparator';


const Wrapper = styled(motion.div)`

`;

const Event = styled.div`
    margin-top: 30px;
    height: 120px;
`;

const Left = styled.div`
    display: inline-block;
    background-color: #0A0A0A;
    height: 120px;
    width: 40%;
    text-align: right;
    position: absolute;
    right: 52%;
    padding-right: 20px;
    @media (max-width: 800px) {
        right: 55%;
        padding-right: 8px;
    }
`;

const Right = styled.div`
    display: inline-block;
    background-color: #0A0A0A;
    height: 120px;
    width: 40%;
    text-align: left;
    position: absolute;
    left: 52%;
    padding-left: 30px;
    @media (max-width: 800px) {
        left: 55%;
        padding-left: 5px;
    }
`;

function ClubEvent({event, index}) {

    const date = new Date(event.utfDate);
    if (index % 2 == 0) {
        return (
            <Wrapper
                initial={{opacity: 0, y: 200}}
                whileInView={{opacity: 1, y: 0}}
                viewport={{once: true}}
            >
                <Event>
                    <Left>
                        <ClubEventDate
                            month={date.toLocaleDateString("en", {month: 'long'})}
                            weekday={date.toLocaleDateString("en", {weekday: 'long'})}
                            day={date.getUTCDate()}
                        />
                    </Left>
                    <ClubEventSeparator/>
                    <Right>
                        <ClubEventName name={event.name}/>
                        <ClubEventDj dj={event.dj}/>
                    </Right>
                </Event>
            </Wrapper>
        );
    } else {
        return (
            <Wrapper
                initial={{opacity: 0, y: 200}}
                whileInView={{opacity: 1, y: 0}}
                viewport={{once: true}}
            >
                <Event>
                    <Right>
                        <ClubEventDate
                            month={date.toLocaleDateString("en", {month: 'long'})}
                            weekday={date.toLocaleDateString("en", {weekday: 'long'})}
                            day={date.getUTCDate()}
                        />
                    </Right>
                    <ClubEventSeparator/>
                    <Left>
                        <ClubEventName name={event.name}/>
                        <ClubEventDj dj={event.dj}/>
                    </Left>
                </Event>
            </Wrapper>
        );
    }
}

export default ClubEvent;