import React from 'react';
import styled from 'styled-components';

const EventDj = styled.p`
    font-family: "Lora";
    font-style: italic;
    color: #cccccc;
    font-weight: 300;
`;

class ClubEvent extends React.Component {
    render() {
        return (
            <EventDj>
                {this.props.dj}
            </EventDj>
        );
    }
}

export default ClubEvent;