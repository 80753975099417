import React from  'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation } from 'react-router-dom';
import { Trans, withTranslation, useTranslation } from "react-i18next";
import {Helmet} from "react-helmet";
import GoogleAnalytics from '../GoogleAnalytics';
import ReactPixel from 'react-facebook-pixel';
import Navigation from './Navigation';

const Wrapper = styled.div`
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    padding-top: 150px;
    padding-bottom: 150px;
    display: block;
    width: 99%;
}
`;

const Header = styled.header`
    font-size: 2em;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
`;

const Paragraph = styled.p`
    font-size: 1.5em;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
`;

const BackLink = styled.a`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em;
    font-weight: bold;
    font-weight: 300;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    float: left;
    margin-top: 200px;
    margin-left: 16%;
    padding-bottom: 150px;
`;

function GalleryPage () {
      const { t, i18n } = useTranslation();
      ReactPixel.init('1576304659568289');
      ReactPixel.pageView();

      return (
          <Wrapper>
           <Helmet>
              <html lang={i18n.language} />
              <meta charSet="utf-8" />
              <title>{t('PRIVACY POLICY')}</title>
              <meta name="description" content={t("Privacy Policy")} />
              <link rel="canonical" href={"https://www.culturebeatclub.com/" + i18n.language + "/policy"} />
              <meta property="og:title" content={t('PRIVACY POLICY')} />
              <meta property="og:type" content="website" />
              <meta property="og:url" content={"https://www.culturebeatclub.com/" + i18n.language + "/policy"} />
              <meta property="og:description" content={t('Privacy Policy')} />
              <meta property="og:site_name" content="Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria" />
            </Helmet>
            <Navigation />
            <Header>
                ПОЛИТИКА ОТНОСНО „БИСКВИТКИТЕ“ (“COOKIES”)
            </Header>
            <Paragraph>
                Използваме Cookies, за да ви предложим по-добра и персонализирана интернет услуга. Данните,
                съхранявани от Cookies, които уебсайтът използва, никога не показват лична информация, от която
                може да се установи самоличността на даден субект.
            </Paragraph>
            <Paragraph>
                „Cookie” е малък текстов файл, който се съхранява и/или прочита от вашия браузър на хард диска на
                вашето устройство (например компютър, лаптоп, таблет или смартфон) от уебсайтовете, които
                посещавате. Cookies също правят работата ви с уебсайтовете по-сигурна и бърза, като запомнят
                вашите предпочитания (например данните ви за регистрация и език), като изпратят информацията,
                която съхраняват, обратно към първоначалния уебсайт (first-party cookie) или към друг уебсайт,
                който ги е задал (third-party cookie).
            </Paragraph>
            <Paragraph>
                Можете да блокирате или премахнете Cookies, като използвате вашия браузър или софтуера на трета
                страна, но е възможно това да ви попречи да използвате определени зони от уебсайта.
            </Paragraph>
            <Paragraph>
                При определени случаи ние директно искаме вашето съгласие сайтът да използва Cookies
                посредством наше Cookies съобщение.
            </Paragraph>
            <Paragraph>
                Cookies, които използваме на уебсайта
            </Paragraph>
            <Paragraph>
                В зависимост от функцията и целта, за която се ползват, обикновено Cookies, които уебсайтът
                използва, се разделят на следните категории:
            </Paragraph>
            <Paragraph>
                 Задължителни Cookies, които ви позволяват да сърфирате в уебсайта и да използвате
                основните му функции. Тези Cookies обикновено се инсталират в отговор на ваши действия,
                които представляват заявка за услуги. Тези Cookies са задължителни за ползването на нашия
                уебсайт.
            </Paragraph>
            <Paragraph>
                 Cookies за функционалност се използват, за да ви разпознаем следващия път, когато посетите
                нашия уебсайт, за да ви предложим подобрени и по-персонализирани функции, като
                например запомняне на вашите предпочитания. Тези Cookies събират анонимна информация
                и не могат да проследят действията ви на други уебсайтове. Валидността на тези Cookies е не
                повече от 2 години.
            </Paragraph>
            <Paragraph>
                 Cookies за аналитичност и ефективност ни позволяват да установим броя на потребителите и
                да събираме информация как се използва уебсайтът (например кои страници най-често
                използва даден потребител и дали получава съобщения за грешка на някоя страница). Това
                ни помага да подобрим работата на нашия уебсайт, като например да осигурим по-лесен
                начин за намиране на това, което търсите Валидността на тези Cookies е не повече от 2
                години.
            </Paragraph>
            <Paragraph>
                 Cookies за рекламиране и таргетиране регистрират вашето посещение в нашия уебсайт,
                страниците, които посещавате и връзките, които следвате. Ще използваме тази информация,
                за да ви показваме реклами, които са съобразени с вас и вашите интереси. Те също се
                използват за ограничаване броя на показванията на дадена реклама и помагат да измерим
                ефективността на рекламните кампании. За целта ние може също да споделяме тази
                информация с трети страни (като рекламодатели). За да използваме тези Cookies, ни е
                необходимо вашето съгласие. Вие можете да управлявате предпочитанията си по отношение
                на Cookies за рекламиране или таргетиране по всяко време посредством настройките на
                вашия браузър (вж. по-долу). Валидността на тези Cookies е не повече от 2 години.
            </Paragraph>
            <Paragraph>
                 Cookies за социални медии ви позволяват да споделяте вашата активност на уебсайта в
                социални мрежи, като Facebook и Twitter. Тези Cookies не са под наш контрол.
            </Paragraph>
            <Paragraph>
                 Други видове Cookies, които може да бъдат използвани на по-късен етап. Ще ви
                информираме чрез актуализиране на нашите политики, в случай че използваме и други
                видове Cookies.
            </Paragraph>
            <Paragraph>
                Моля да имате предвид, че използваме услугите на трети страни, за да научим как използвате нашия
                уебсайт, с цел да оптимизираме работата ви и да ви показваме рекламно съдържание извън нашия
                уебсайт. Тези трети страни (включително например, рекламни мрежи и доставчици на външни
                услуги като анализ на интернет трафика) може също да използват cookies, върху които ние нямаме
                контрол.
            </Paragraph>
            <Paragraph>
                Вие можете да оттеглите вашето съгласие по отношение използването на Cookies по всяко време. За
                да направите това, моля вижте условията по-долу.
            </Paragraph>
            <Paragraph>
                Ако искате да изтриете Cookies, които се съхраняват на вашето устройство и да конфигурирате
                браузъра си да отказва Cookies, можете да го направите от настройките за предпочитания на вашия
                интернет браузър. Обичайно настройките, свързани с Cookies, се намират в следните менюта:
                &quot;Options&quot;, &quot;Tools&quot; или &quot;Preferences&quot; на браузъра, който използвате за достъп до интернет. В
                зависимост от браузъра, Cookies могат да се деактивират по различен начин. За повече информация,
                можете да посетите уебсайта на вашия браузър.
            </Paragraph>
            <Paragraph>
                Cookies използвани за статистически цели и мониторинг на интернет трафика
            </Paragraph>
            <Paragraph>
                Google Analytics
            </Paragraph>
            <Paragraph>
                Нашият уебсайт използва Google Analytics, услуга за анализ на интернет трафика, предоставена от
                Google. Google Analytics използва cookies, за да подпомогне уебсайта да анализира как го ползвате.
                Информацията, събрана от cookie, свързана с вашето ползване на уебсайта (включително вашия IP
                адрес) ще бъде пренесена до и съхранена от Google [на сървъри в САЩ]. Google ще използва тази
                информация, за да оценява как използвате уебсайта, като компилира данни за активност за
                отделните уебсайт оператори и предоставя и други услуги, свързани с отчетената активност на
                уебсайта и ползването на интернет. Възможно е Google да прехвърля тази информация на трети
                страни, когато това се изисква по закон или в случаите, когато тези трети страни обработват
                информация от името на Google. Google няма да свързва вашия IP адрес с други данни, които се
                съхраняват от Google.
            </Paragraph>
            <Paragraph>
                Вие можете да решите да се откажете от Google Analytics, без това да повлияе на възможността ви за
                посещаване на нашия уебсайт.
            </Paragraph>
            <Paragraph>
                Подробна информация за Google Analytics и поверителност (включително как можете да
                контролирате информацията, която се изпраща на Google) може да се намери на
                https://policies.google.com/privacy/partners.
            </Paragraph>
            <Paragraph>
                С цел да предотвратите евентуално проследяване, можете да инсталирате „add-in“ на вашия браузър
                ( http://tools.google.com/dlpage/gaoptout ).
            </Paragraph>
            <Paragraph>
                Cookies използвани за директен маркетинг
            </Paragraph>
            <Paragraph>
                Facebook Pixel
            </Paragraph>
            <Paragraph>
                Това е кодът, който поставяме в уебсайта си. Помага ни да следим конверсиите от Facebook рекламите,
                помага ни и да ги оптимизираме въз основа на събраните данни, да изграждаме целеви аудитории за
                бъдещи реклами и да ремаркетираме потенциални клиенти – хора, които са предприели действие на
                уебсайта ни. Facebook Pixel работи като поставя и задейства бисквитки, които следят потребителите,
                които взаимодействат със сайта и рекламите във Facebook.
                Подробна информация за Facebook Pixel може да се намери на:
                https://www.facebook.com/business/help/742478679120153
            </Paragraph>
            <BackLink>
                <FontAwesomeIcon size="lg" icon={faChevronLeft} />
                <Link to="/"><Trans>Back</Trans></Link>
            </BackLink>
          </Wrapper>
      );
}

export default withTranslation()(GalleryPage);