import emailjs from '@emailjs/browser';
import {
    faChampagneGlasses,
    faChevronLeft,
    faGift,
    faIcons,
    faMartiniGlassCitrus,
    faPaperPlane,
    faSpinner,
    faCircleExclamation
} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {motion} from 'framer-motion/dist/framer-motion'
import React, {useRef, useState} from 'react';
import {Trans} from "react-i18next";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import styled from 'styled-components';
import validator from 'validator'
import CarouselParties from './CarouselParties';
import SectionDetails from './SectionDetails';
import SectionHeader from './SectionHeader';
import 'react-clock/dist/Clock.css';

const Wrapper = styled(motion.div)`
    display: block;
    width: 100%;
    scroll-margin: 100px;
`

const StyledSectionDetails = styled(SectionDetails)`
    @media (max-width: 800px) {
        margin-top: 0px;
        height: 30px;
    }
`

const WrapperForm = styled(motion.div)`
    height: 600px;
    margin-bottom: 150px;
    width: 100%;
`
const InformationWrapper = styled(motion.div)`
    display: block;
    height: 600px;
    width: 100%;
    margin-top: 80px;
    @media (max-width: 800px) {
        height: 300px;
        margin-top: 20px;
    }
`

const Form = styled(motion.form)`
    width: 500px;
    text-align: left;
    margin: 0 auto;
    margin-top: 50px;
    @media (max-width: 800px) {
        width: 280px;
    }
`

const StyledInput = styled.input`
    font-family: 'Montserrat';
    width: 99%;
    height: 30px;
    border-radius: 5px;
    font-size: 1.2em;
    margin-bottom: 10px;
`

const StyledTextArea = styled.textarea`
    font-family: 'Montserrat';
    width: 99%;
    height: 120px;
    border-radius: 5px;
    font-size: 1.2em;
    margin-bottom: 10px;
`

const StyledButton = styled.button`
    font-size: 2em;
    background-color: black;
    color: white;
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: inline-block;
    border: none;

    &:hover {
        opacity: .8;
    }
`

const StyledButtonSubmit = styled(StyledButton)`
    margin-left: 400px;
    @media (max-width: 800px) {
        margin-left: 140px;
    }
`

const StyledButtonIcon = styled(FontAwesomeIcon)`
    margin-top: 0px;
`
const Label = styled.label`
    font-family: 'Montserrat';
    display: block;
    font-size: 1em;
    @media (max-width: 800px) {
        font-size: .8em;
    }
`

const Information = styled.div`
    font-family: "Lora";
    font-size: 2em;
    font-style: italic;
    font-weight: 300;
    color: #c1c1c1;
    margin-top: 100px;
    width: 70%;
    display: inline-block;
    @media (max-width: 800px) {
        font-size: 1.2em;
    }
`;

const Icon = styled(FontAwesomeIcon)`
    width: 100%;
    text-align: center;
    display: block;
    font-size: 5em;
    margin-top: 120px;
`

const Card = styled.div`
    width: calc(80% / 4);
    margin: 2%;
    font-weight: 600px;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;

    &:hover {
        opacity: .8;
    }
`

const CardsWrapper = styled.div`
    width: 80%;
    margin: 0 auto;
    text-align: center;
`

const CardHeader = styled.div`
    font-family: 'Montserrat';
    width: 100%;
    text-align: center;
    display: block;
    font-size: 1.3em;
    font-weight: 900;
    margin-top: 50px;
    @media (max-width: 800px) {
        font-size: .7em;
        margin-top: 10px;
    }
`

const CardInfo = styled.div`
    font-family: 'Montserrat';
    width: 100%;
    text-align: center;
    display: block;
    font-size: 1em;
    margin-top: 20px;
    @media (max-width: 800px) {
        font-size: .6em;
        margin-top: 5px;
    }
    @media (max-width: 800px) {
        display: none;
    }
`


const CardIcon = styled(FontAwesomeIcon)`
    width: 100%;
    text-align: center;
    display: block;
    font-size: 3em;
    margin-top: 50px;
    @media (max-width: 800px) {
        font-size: 2em;
    }
`

const TimeWrapper = styled.div`
    font-family: 'Montserrat';
    background-color: white;
    color: black;
    width: 130px;
    height: 30px;
    border-radius: 5px;
    font-size: 1.2em;
    margin-bottom: 10px;

`

function Reservation() {
    const formToSend = useRef();
    const currentView = useRef();
    const [form, setForm] = useState("formInitial");
    const [emailType, setEmailType] = useState(null);
    const [emailError, setEmailError] = useState(null)
    const [nameError, setNameError] = useState(null)
    const [phoneError, setPhoneError] = useState(null)
    const [dateError, setDateError] = useState(null)
    const [timeError, setTimeError] = useState(null)
    const [guestsError, setGuestsError] = useState(null)
    const [currentDate, setCurrentDate] = useState(null)
    const [time, onChange] = useState('21:00');

    const startReservation = (type) => {
        setForm("formInfo")
        setEmailType(type)
        currentView.current.scrollIntoView()
    }

    const returnToInitial = () => {
        setForm("formInitial")
        currentView.current.scrollIntoView()
    }

    const sendEmail = (e) => {
        e.preventDefault();

        let isEmailValid = validateEmail(e);
        let isNameValid = validateName(e);
        let isPhoneValid = validatePhone(e);
        let isDateValid = validateDate(e);
        let isTimeValid = validateTime(e);
        let isGuestsValid = validateGuests(e);

        if (isEmailValid && isNameValid && isPhoneValid && isGuestsValid && isDateValid) {
            setForm("formSending")
            currentView.current.scrollIntoView()
            emailjs.sendForm('service_t7ophg9', 'template_3yjnjg2', formToSend.current, '3f9_BiSGqWCpaRacc')
                .then((result) => {
                    setForm("formSuccess")
                    currentView.current.scrollIntoView()
                }, (sendingError) => {
                    console.log(sendingError.text);
                    setForm("formError")
                    currentView.current.scrollIntoView()
                });
        }
    };

    const validateEmail = () => {
        let email = formToSend.current.email.value
        if (email && validator.isEmail(email)) {
            setEmailError(null)
            return true;
        } else {
            setEmailError(' - Моля, въведете валиден имейл адрес.')
            return false;
        }
    }

    const validateName = () => {
        if (formToSend.current.name.value) {
            setNameError(null)
            return true;
        } else {
            setNameError(' - Полето не може да бъде празно.')
            return false;
        }
    }

    const validatePhone = () => {
        if (formToSend.current.phone.value) {
            setPhoneError(null)
            return true;
        } else {
            setPhoneError(' - Моля въведете валиден телефон.')
            return false;
        }
    }

    const validateDate = () => {
        if (formToSend.current.date.value) {
            const date = formToSend.current.date.value;
            setDateError(null);
            return true;
        } else {
            setDateError(' - Моля изберете дата.')
            return false;
        }
    }

    const validateTime = () => {
        if (formToSend.current.date.value) {
            setTimeError(null)
            return true;
        } else {
            setTimeError(' - Моля изберете час.')
            return false;
        }
    }

    const validateGuests = () => {
        if (formToSend.current.guests.value) {
            setGuestsError(null)
            return true;
        } else {
            setGuestsError(' - Полето не може да бъде празно.')
            return false;
        }
    }

    if (form === "formInitial") {
        return (
            <Wrapper ref={currentView}
                     initial={{opacity: 0, y: -200}}
                     whileInView={{opacity: 1, y: 0}}
                     viewport={{once: true}}
                     transition={{delay: 0.3, delayChildren: 0.5}}>
                <SectionHeader title={<Trans>RESERVE</Trans>}/>
                <StyledSectionDetails title={<Trans>Create Reservation</Trans>}/>
                <CarouselParties/>
                <InformationWrapper
                    initial={{opacity: 0}}
                    whileInView={{opacity: 1}}
                    viewport={{once: true}}
                >

                    <CardsWrapper>
                        <Card onClick={() => startReservation("Фирмено Събитие")}>
                            <CardIcon size="lg" icon={faMartiniGlassCitrus}/>
                            <CardHeader><Trans>Фирмено Събитие</Trans></CardHeader>
                            <CardInfo><Trans>Corporate Event Reservation Description</Trans></CardInfo>
                        </Card>
                        <Card onClick={() => startReservation("Личен Повод")}>
                            <CardIcon size="lg" icon={faIcons}/>
                            <CardHeader><Trans>Личен Повод</Trans></CardHeader>
                            <CardInfo><Trans>Personal Event Reservation Description</Trans></CardInfo>
                        </Card>
                        <Card onClick={() => startReservation("Коледно Парти")}>
                            <CardIcon size="lg" icon={faGift}/>
                            <CardHeader><Trans>Christmas Party</Trans></CardHeader>
                            <CardInfo><Trans>Christmas Party Description</Trans></CardInfo>
                        </Card>
                        <Card onClick={() => startReservation("Друго Събитие")}>
                            <CardIcon size="lg" icon={faChampagneGlasses}/>
                            <CardHeader><Trans>Other Event</Trans></CardHeader>
                            <CardInfo><Trans>Other Event Description</Trans></CardInfo>
                        </Card>
                    </CardsWrapper>
                </InformationWrapper>
            </Wrapper>
        )
    } else if (form === "formInfo") {
        return (
            <WrapperForm ref={currentView}
                         initial={{opacity: 0, x: -200}}
                         whileInView={{opacity: 1, x: 0}}
                         viewport={{once: true}}
                         transition={{delay: 0.3, delayChildren: 0.5}}>
                <SectionHeader title={<Trans>RESERVE</Trans>}/>
                <SectionDetails title={<Trans>{emailType}</Trans>}/>
                <Form ref={formToSend}>
                    <Label htmlFor="email">Email * {emailError}</Label>
                    <StyledInput type="email" name="email" id="email"/>
                    <Label htmlFor="name"><Trans>Name</Trans> * {nameError}</Label>
                    <StyledInput type="text" name="name" id="name"/>
                    <Label htmlFor="phone"><Trans>Phone</Trans> * {phoneError}</Label>
                    <StyledInput type="text" name="phone" id="phone"/>
                    <Label htmlFor="date"><Trans>Date</Trans> * {dateError}</Label>
                    <StyledInput type="date" name="date" id="date"/>
                    <Label htmlFor="time"><Trans>Time</Trans> * {timeError}</Label>
                    <TimeWrapper>
                        <TimePicker clearIcon="" name="time" id="time" minTime="19:00" maxTime="23:00"
                                    onChange={onChange} value={time}/>
                    </TimeWrapper>
                    <Label htmlFor="guests"><Trans>Number of guests</Trans> * {guestsError}</Label>
                    <StyledInput min="1" max="500" type="number" name="guests" id="guests"/>
                    <Label htmlFor="message"><Trans>Additional Information</Trans></Label>
                    <StyledTextArea type="textarea" name="message" id="message"/>
                    <StyledInput type="hidden" name="type" id="type" value={emailType}/>
                    <StyledInput type="hidden" name="translated_date" id="translated_date" value={currentDate}/>
                    <br/>
                    <StyledButton type="button" onClick={(e) => returnToInitial()}><StyledButtonIcon size="lg"
                                                                                                     icon={faChevronLeft}/></StyledButton>
                    <StyledButtonSubmit type="submit" onClick={(e) => sendEmail(e)}><StyledButtonIcon size="lg"
                                                                                                      icon={faPaperPlane}/></StyledButtonSubmit>
                </Form>
            </WrapperForm>
        )
    } else if (form === "formSuccess") {
        return (
            <Wrapper ref={currentView}
                     initial={{opacity: 0, x: -200}}
                     whileInView={{opacity: 1, x: 0}}
                     viewport={{once: true}}
                     transition={{delay: 0.3, delayChildren: 0.5}}
                     onClick={() => returnToInitial()}>
                <SectionHeader title={<Trans>RESERVE</Trans>}/>
                <InformationWrapper>
                    <Information><Trans>Success Message</Trans></Information>
                    <Icon size="lg" icon={faChampagneGlasses}/>
                </InformationWrapper>
            </Wrapper>
        )
    } else if (form === "formError") {
        return (
            <Wrapper ref={currentView}
                     initial={{opacity: 0, x: -200}}
                     whileInView={{opacity: 1, x: 0}}
                     viewport={{once: true}}
                     transition={{delay: 0.3, delayChildren: 0.5}}
                     onClick={() => returnToInitial()}>
                <SectionHeader title={<Trans>RESERVE</Trans>}/>
                <InformationWrapper>
                    <Information><Trans>Error Message</Trans></Information>
                    <Icon size="lg" icon={faCircleExclamation}/>
                </InformationWrapper>
            </Wrapper>
        )
    } else if (form === "formSending") {
        return (
            <Wrapper ref={currentView}
                     initial={{opacity: 0, x: -200}}
                     whileInView={{opacity: 1, x: 0}}
                     viewport={{once: true}}
                     transition={{delay: 0.3, delayChildren: 0.5}}
                     onClick={() => returnToInitial()}>
                <SectionHeader title={<Trans>RESERVE</Trans>}/>
                <InformationWrapper>
                    <Information><Trans>Sending Message</Trans></Information>
                    <Icon spin="true" spinPulse="true" size="lg" icon={faSpinner}/>
                </InformationWrapper>
            </Wrapper>
        )
    }
}

export default Reservation;