import {useEffect} from 'react';
import ReactGA from "react-ga4";
import {useLocation} from 'react-router-dom';

function GoogleAnalytics() {
    const location = useLocation();

    useEffect(() => {
        ReactGA.send("pageview");
    }, [location]);
}

export default GoogleAnalytics;