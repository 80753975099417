import React from 'react';
import styled from 'styled-components';

const EventName = styled.p`
    font-size: 1.3em;
    font-style: normal;
    font-weight: 700;
    font-family: "Montserrat";
    text-transform: none;
    letter-spacing: -1px;
    word-spacing: 0;
    @media (max-width: 800px) {
        font-size: 1em;
    }
`;


class ClubEventDj extends React.Component {
    render() {
        return (
            <EventName>
                {this.props.name}
            </EventName>
        );
    }
}

export default ClubEventDj;