import {icon, toHtml} from "@fortawesome/fontawesome-svg-core";
import {faCirclePlay, faCircleStop} from '@fortawesome/free-solid-svg-icons'
import {motion} from 'framer-motion/dist/framer-motion'
import React, {useEffect, useState} from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import videoMp4 from '../media/videos/radio_background_circle.mp4';
import videoOgv from '../media/videos/radio_background_circle.ogv';
import videoWebm from '../media/videos/radio_background_circle.webm';
import SectionDetails from './SectionDetails';
import SectionHeader from './SectionHeader';


const Wrapper = styled(motion.div)`
    margin-bottom: 50px;
    display: block;
    height: 80vh;
    overflow: hidden;
    @media (max-width: 1200px) {
        margin-bottom: -150px;
        height: 100vh;
        margin-top: -100px;
    }
`

const Player = styled.div`
    position: relative;
    background-size: cover;
    background-position: 50% 50%;
    height: 100vh;
    z-index: 0;
    top: 50px;
`;

const StyledVideo = styled.video.attrs(props => ({
    preload: "preload",
    autoPlay: "autoPlay",
    loop: "loop",
    muted: "muted",
    playsInline: "playsInline"
}))`
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    @media (max-width: 1200px) {
        top: 420px;
    }
`;

const BackgroundVideo = styled(motion.div)`
    position: absolute;
    width: 100%;
`

const PlayerMask = styled.div`
    width: 100%;
    height: 500px;
    position: relative;
    background-image: url(${props => props.isActive ? getSVGURI(faCircleStop, 'white') : getSVGURI(faCirclePlay, 'white')});
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 120px 120px;
`

const StyledBackgroundVideo = styled(BackgroundVideo)`
    top: -220px;
    z-index: -1;
`

const StyledPlayer = styled(ReactPlayer)`
    display: block;
`;

const Volume = styled.input`
    width: 400px;
    @media (max-width: 1200px) {
        display: none;
    }
`

const getSVGURI = (direction, color) => {
    const abstract = icon(direction).abstract[0];
    if (color) abstract.children[0].attributes.fill = color;
    return `data:image/svg+xml;base64,${btoa(toHtml(abstract))}`;
};

function RadioPlayer({togglePlay, isPlaying}) {

    const [isActive, setIsActive] = useState(isPlaying);
    const [volumeBar, setVolumeBar] = useState(0.5)

    useEffect(() => {
        setIsActive(isPlaying)
    }, [isPlaying])

    const handleClick = () => {
        setIsActive(current => !current);
    };

    const handleVolume = (e) => {
        setVolumeBar(e.target.value / 100)
    };

    return (
        <Wrapper
            initial={{opacity: 0}}
            whileInView={{opacity: 1}}
            viewport={{once: true}}
        >
            <SectionHeader title={"CULTURE BEAT RADIO (Beta)"}/>
            <SectionDetails title={"Special Mixes For Radio Energy"}/>
            <PlayerMask onClick={togglePlay} isActive={isActive}>
                <StyledBackgroundVideo>
                    <Player>
                        <StyledVideo style={{visibility: isActive ? 'visible' : 'hidden'}}>
                            <source src={videoMp4} type="video/mp4"/>
                            <source src={videoOgv} type='video/ogg; codecs="theora, vorbis"'/>
                            <source src={videoWebm} type="video/webm"/>
                            Your browser does not support HTML5 video.
                        </StyledVideo>
                    </Player>
                </StyledBackgroundVideo>
            </PlayerMask>
        </Wrapper>
    );

}

export default RadioPlayer;
