import React, {useState} from 'react';
import Masonry from "react-responsive-masonry"
import styled from 'styled-components';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/dist/styles.css";
import {images} from '../media/images/events/november/highlights.js';

const slides = images.map(({original, width, height}) => ({
    src: original,
    width,
    height,
}));

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin-top: 80px;
    margin-bottom: 150px;
`;

const StyledMasonry = styled(Masonry)`
    cursor: pointer;
`

function Highlights() {
    const [index, setIndex] = useState(-1);

    return (
        <Wrapper>
            <StyledMasonry columnsCount={6} gutter="2px">
                {images.map((image, i) => (
                    <img
                        key={i}
                        src={image.src}
                        style={{width: "100%", display: "block"}}
                        onClick={() => setIndex(i)}
                    />
                ))}
            </StyledMasonry>
            <Lightbox
                slides={slides}
                open={index >= 0}
                index={index}
                close={() => setIndex(-1)}
            />
        </Wrapper>
    );
}

export default Highlights;