import {faCircle} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react';
import styled from 'styled-components';

const CircleWrapper = styled.div`
    margin-top: -2px;
    margin-left: -10px;
`;

const EventSeparator = styled.div`
    display: inline-block;
    height: 150px;
    width: 0px;
    border: 1px solid #ffffff;
    position: relative;
    top: 20px;
    @media (max-width: 800px) {
        top: 17px;
    }
`;

class ClubEventSeparator extends React.Component {
    render() {
        return (
            <EventSeparator>
                <CircleWrapper>
                    <FontAwesomeIcon size="lg" icon={faCircle}/>
                </CircleWrapper>
            </EventSeparator>
        );
    }
}

export default ClubEventSeparator;