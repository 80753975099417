import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import {A11y, Autoplay, Navigation, Scrollbar} from 'swiper';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react'
import events from "./clubevents/events.json";

const Wrapper = styled.div`
    width: 100%;
    margin-top: 100px;
    display: block;
    @media (max-width: 1200px) {
        margin-top: 100px;
    }
`;

const Slide = styled(SwiperSlide)`
    width: 100%;
`

const StyledImage = styled.img`
    width: 48%;
    margin: 10px;
    @media (max-width: 1100px) {
        width: 60%;
    }

    &:hover {
        opacity: .8;
    }
`

function Carousel() {
    const {t, i18n} = useTranslation();
    const today = new Date();

    return (
        <Wrapper>
            <Swiper
                modules={[Navigation, Scrollbar, A11y, Autoplay]}
                loop={true}
                spaceBetween={0}
                navigation={{clickable: true}}
            >
                {events.filter((event) => new Date(event.utfDate) > today && event.image !== undefined).map((event, index) => {
                    return <Slide>
                        <Link key={"key" + index} to={"/" + i18n.language + "/events/" + event.date}>
                            <StyledImage src={event.image} alt={event.name + " " + event.date}
                                         title={event.name + " " + event.date}/>
                        </Link>
                    </Slide>
                })}
            </Swiper>
        </Wrapper>
    );
}

export default Carousel;