import {motion} from 'framer-motion/dist/framer-motion'
import React from 'react';
import {Trans} from "react-i18next";
import styled from 'styled-components';

const StyledFooter = styled(motion.div)`
    font-family: 'Montserrat', sans-serif;
    font-size: 2em;
    font-weight: 600;
    letter-spacing: -3px;
    -webkit-font-smoothing: antialiased;
    color: #efefef;
    display: block;
    margin-top: 250px;
}
`;

class Footer extends React.Component {
  render() {
      return (
          <StyledFooter
                initial={{ opacity: 0.2 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                >
                <Trans>FOLLOW US</Trans>
          </StyledFooter>
      );
    }
}

export default Footer;