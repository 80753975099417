import React from 'react';
import {Trans} from "react-i18next";
import styled from 'styled-components';

const EventDate = styled.p`
    font-size: 1.3em;
    font-style: normal;
    font-weight: 700;
    font-family: "Lora";
    text-transform: none;
    letter-spacing: -1px;
    word-spacing: 0;
    color: #cccccc;
    @media (max-width: 800px) {
        font-size: 1em;
    }
`;

function Capitalize(str) {
    const string = str.charAt(0).toUpperCase() + str.slice(1);
    return <Trans>{string}</Trans>;
}

class ClubEventDate extends React.Component {

    render() {
        const weekday = Capitalize(this.props.weekday);
        const month = Capitalize(this.props.month);
        return (
            <EventDate>
                {weekday}, {month} {this.props.day}
            </EventDate>
        );
    }
}

export default ClubEventDate;