import React from  'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation, Trans } from "react-i18next";


const EventDate = styled.div`
    font-family: "Lora";
    font-size: 2.3em;
    display: block;
    font-style: italic;
    font-weight: 500;
    color: #c1c1c1;
    margin-top: -60px;
    @media (max-width: 1300px) {
        font-size: 1em;
        margin-top: -25px;
    }
`;

function Capitalize(str) {
    const string = str.charAt(0).toUpperCase() + str.slice(1);
    return <Trans>{string}</Trans>;
}

class SingleClubEventDate extends React.Component {

  render() {
      const weekday = Capitalize(this.props.weekday);
      const month = Capitalize(this.props.month);
      return (
        <EventDate>
            {weekday}, {month}  {this.props.day}
        </EventDate>
      );
    }
}

export default SingleClubEventDate;