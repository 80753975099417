import React, {useLayoutEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import styled from 'styled-components';
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from 'swiper';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react'
import {images1Bg} from '../media/images/menu/menu_images_1_bg.js';
import {images1En} from '../media/images/menu/menu_images_1_en.js';
import {images2Bg} from '../media/images/menu/menu_images_2_bg.js';
import {images2En} from '../media/images/menu/menu_images_2_en.js';
import {images3Bg} from '../media/images/menu/menu_images_3_bg.js';
import {images3En} from '../media/images/menu/menu_images_3_en.js';
import {imagesBg} from '../media/images/menu/menu_images_bg.js';
import {imagesEn} from '../media/images/menu/menu_images_en.js';

const Wrapper = styled.div`
    width: 100%;
    display: block;
    margin-top: 80px;
    margin-bottom: 0px;
    @media (max-width: 1200px) {
        margin-bottom: 0px;
    }
`;

const SingleWrapper = styled.div`
    width: 100%;
    display: block;
    margin-top: 80px;
    margin-bottom: 0px;
    @media (max-width: 1200px) {
        margin-bottom: 0px;
    }
`;

const MenuItem = styled.img`
    width: 20%;
    float: left;
    margin-left: 10%;
`;

const MenuItemSingle = styled.img`
    width: 80%;
    display: block;
    float: left;
    margin-left: 10%;
`;

const Slide = styled(SwiperSlide)`
    width: 100%;
    margin-bottom: 100px;
`

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {

        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);

    }, []);
    return size;
}

function Menu() {
    const [width, height] = useWindowSize();
    const {i18n} = useTranslation();
    let images;
    let images1;
    let images2;
    let images3;

    if (i18n.language === 'bg') {
        images = imagesBg;
        images1 = images1Bg;
        images2 = images2Bg;
        images3 = images3Bg;
    } else {
        images = imagesEn;
        images1 = images1En;
        images2 = images2En;
        images3 = images3En;
    }


    if (width > 1000) {
        return (
            <Wrapper>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    loop={true}
                    spaceBetween={0}
                    navigation={{clickable: true}}
                    pagination={{clickable: true}}>
                    <Slide>
                        {images1.map((image, i) => (
                            <MenuItem
                                key={i}
                                src={image.src}
                            />
                        ))}
                    </Slide>
                    <Slide>
                        {images2.map((image, i) => (
                            <MenuItem
                                key={i}
                                src={image.src}
                            />
                        ))}
                    </Slide>
                    <Slide>
                        {images3.map((image, i) => (
                            <MenuItem
                                key={i}
                                src={image.src}
                            />
                        ))}
                    </Slide>
                </Swiper>
            </Wrapper>
        )
    } else {
        return (
            <SingleWrapper>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    loop={true}
                    spaceBetween={0}
                    navigation={{clickable: true}}
                    pagination={{clickable: true}}>
                    {images.map((image, i) => (
                        <Slide>
                            <MenuItemSingle
                                key={i}
                                src={image.src}
                            />
                        </Slide>
                    ))}
                </Swiper>
            </SingleWrapper>
        );
    }
}

export default Menu;