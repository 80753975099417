import React, {useState, useEffect} from 'react';
import ReactGA from "react-ga4";
import styled from "styled-components";
import GlobalStyle from './elements/GlobalStyle';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    Outlet,
    createBrowserRouter,
    RouterProvider
} from 'react-router-dom';
import Main from './Main';
import MainRadioPlayer from './MainRadioPlayer';
import NotFoundPage from './NotFoundPage';
import ReservePage from './components/ReservePage';
import EventsPage from './components/EventsPage';
import EventPage from './components/EventPage';
import RadioPage from './components/RadioPage';
import GalleryPage from './components/GalleryPage';
import ContactPage from './components/ContactPage';
import PolicyPage from './components/PolicyPage';
import ConditionsPage from './components/ConditionsPage';
import CookiesPage from './components/CookiesPage';
import CookieConsent from "react-cookie-consent";
import {useTranslation, Trans} from "react-i18next";

const Wrapper = styled.div`
    width: 100%;
`

const Section = styled.section`
    padding-top: 80px;
`;

const Icon = styled(FontAwesomeIcon)`
    width: 100%;
    text-align: center;
    display: block;
    font-size: 5em;
    margin-top: 400px;
    @media (max-width: 1200px) {
        margin-top: 300px;
    }
`

const ShowCookiePolicyButton = styled.span`
    font-size: 18px;
    margin-left: 5px;
    cursor: pointer;
`

function AppFill() {

    ReactGA.initialize("G-6T2TH4LN18");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 1500);
    }, []);

    const [isPlaying, setIsPlaying] = useState(false);

    const togglePlay = () => {
        setIsPlaying(current => !current);
    };

    const router = createBrowserRouter([
        {
            path: "/",
            element: <Main togglePlay={togglePlay} isPlaying={isPlaying}/>,
        },
        {
            path: ":lang",
            children: [
                {
                    index: true,
                    element: <Main togglePlay={togglePlay} isPlaying={isPlaying}/>
                }, {
                    path: "events",
                    children: [{
                        element: <Outlet/>,
                        children: [{
                            index: true,
                            element: <EventsPage/>,
                        }, {
                            path: ":date",
                            element: <EventPage/>,
                        }]
                    }]
                }, {
                    path: "mix",
                    element: <RadioPage togglePlay={togglePlay} isPlaying={isPlaying}/>
                }, {
                    path: "gallery",
                    element: <GalleryPage/>
                }, {
                    path: "reserve",
                    element: <ReservePage/>
                }, {
                    path: "contact",
                    element: <ContactPage/>
                }, {
                    path: "policy",
                    element: <PolicyPage/>
                }, {
                    path: "conditions",
                    element: <ConditionsPage/>
                }, {
                    path: "cookies",
                    element: <CookiesPage/>
                }, {
                    path: "*",
                    element: <NotFoundPage/>,
                }
            ]
        },
        {
            path: "*",
            element: <NotFoundPage/>,
        }
    ]);


    return (
        <React.Fragment>
            <GlobalStyle/>

            <Wrapper>
                <MainRadioPlayer togglePlay={togglePlay} isPlaying={isPlaying}/>
                <RouterProvider router={router}>
                    <Outlet/>
                </RouterProvider>
            </Wrapper>
        </React.Fragment>
    )
}

function App() {
    const {t, i18n} = useTranslation();
    const [showCookiePolicy, setShowCookiePolicy] = useState("hideCookiePolicy");

    const triggerCookiePolicyTrigger = () => {
        if (showCookiePolicy === "hideCookiePolicy") {
            setShowCookiePolicy("showCookiePolicy")
        } else {
            setShowCookiePolicy("hideCookiePolicy")
        }
    }


    return (
        <Wrapper>
            <AppFill/>
            <CookieConsent
                location="bottom"
                buttonText="OK"
                cookieName="cookiesConsent"
                style={{background: "#242424", fontSize: "18px"}}
                buttonStyle={{background: "white", color: "#4e503b", fontSize: "15px", borderRadius: "2px"}}
                expires={150}
            >
                <Trans>CookiePolicy</Trans>
            </CookieConsent>
        </Wrapper>
    )
}

export default App;