import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet";
import {Trans, useTranslation, withTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import GoogleAnalytics from '../GoogleAnalytics';
import Contact from './Contact';
import Highlights from './Highlights';
import Navigation from './Navigation';
import SectionDetails from './SectionDetails';
import SectionHeader from './SectionHeader';
import Social from './Social';

const Wrapper = styled.div`
    padding-top: 150px;
    padding-bottom: 150px;
}
`;

const BackLink = styled.a`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em;
    font-weight: bold;
    font-weight: 300;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    float: left;
    margin-top: 200px;
    margin-left: 16%;
    padding-bottom: 150px;
}
`;

const ContactWrapper = styled.div`
    margin-top: 200px;
}
`;

const SocialWrapper = styled.div`
    margin-top: 200px;
}
`;

function GalleryPage() {
    const {t, i18n} = useTranslation();
    ReactPixel.init('1576304659568289');
    ReactPixel.pageView();

    return (
        <Wrapper>
            <Helmet>
                <html lang={i18n.language}/>
                <meta charSet="utf-8"/>
                <title>{t('HIGHLIGHTS')}</title>
                <meta name="description" content={t("Highlights From Last Month")}/>
                <link rel="canonical" href={"https://www.culturebeatclub.com/" + i18n.language + "/gallery"}/>
                <meta property="og:title" content={t('HIGHLIGHTS')}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content={"https://www.culturebeatclub.com/images/pages/gallery.png"}/>
                <meta property="og:url" content={"https://www.culturebeatclub.com/" + i18n.language + "/gallery"}/>
                <meta property="og:description" content={t('Highlights From Last Month')}/>
                <meta property="og:site_name" content="Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria"/>
            </Helmet>
            <GoogleAnalytics/>
            <Navigation/>
            <SectionHeader title={<Trans>HIGHLIGHTS</Trans>}/>
            <SectionDetails title={<Trans>Highlights From Last Month</Trans>}/>
            <Highlights/>
            <ContactWrapper><Contact/></ContactWrapper>
            <SocialWrapper><Social/></SocialWrapper>
            <BackLink>
                <FontAwesomeIcon size="lg" icon={faChevronLeft}/>
                <Link to="/"><Trans>Back</Trans></Link>
            </BackLink>
        </Wrapper>
    );
}

export default withTranslation()(GalleryPage);