import  { createGlobalStyle }  from  'styled-components';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'
import { toHtml, icon } from "@fortawesome/fontawesome-svg-core";


const getSVGURI = (direction, color) => {
  const abstract = icon(direction).abstract[0];
  if (color) abstract.children[0].attributes.fill = color;
  return `data:image/svg+xml;base64,${btoa(toHtml(abstract))}`;
};

const  GlobalStyle  =  createGlobalStyle`
    html {
      background-color: black;
    }

    body {
        margin: 0 auto;
        font-family: font-family: 'Raleway', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        background-color: black;
        color: white;
        text-align: center;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    a {
        text-decoration: none;
        color: #ffffff;
    }

    .Typewriter {
        font-family: 'Montserrat', sans-serif;
        font-size: 4.2em;
        font-weight: bold;
        font-weight: 600;
        letter-spacing: -1px;
        -webkit-font-smoothing: antialiased;
        color: #efefef;
        display: block;
        margin-top: 50px;
        @media (max-width: 1300px) {
            font-size: 1.6em;
        }
    }

    .swiper-button-prev {
        background-image: url(${getSVGURI(faAngleLeft, 'white')});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-color: #1b1b1b;
        font-size: 5px;
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        color: white;
        opacity: .5;
        margin-left: 50px;
        @media (max-width: 1100px) {
            margin-left: 5px;
        }
    }

    .swiper-button-prev::after {
        display: none;
    }

    .swiper-button-prev:hover {
        opacity: 1;
    }

    .swiper-button-next {
        background-image: url(${getSVGURI(faAngleRight, 'white')});
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px 20px;
        background-color: #1b1b1b;
        font-size: 5px;
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 30px;
        color: white;
        margin-right: 50px;
        opacity: .5;
        @media (max-width: 1100px) {
            margin-right: 5px;
        }
    }

    .swiper-button-next::after {
        display: none;
    }

    .swiper-button-next:hover {
        opacity: 1;
    }

    .swiper-pagination-bullet {
        color: #1b1b1b;
        opacity: 1;
    }

    .swiper-pagination-bullet-active {
        opacity: 0.3;
        background-color: white;
    }

    .swiper-pagination-bullet::after {
        display: none;
    }

    input[type=range]::-webkit-slider-runnable-track {
        animate: 0.2s;
        background: white;
        height: 3px;
        display: block;
        width: 400px;
    }

    input[type=range]::-webkit-slider-thumb {
        height: 30px;
        width: 15px;
        border-radius: 5px;
        background: #FFFFFF;
        cursor: pointer;
        -webkit-appearance: none;
        margin-top: -13px;
    }

    iframe {
        width: 100%;
        height: 100%;
    }

    react-time-picker__inputGroup__input {
        background: white;
        color: black;
    }
`;

export  default GlobalStyle;