import {motion} from 'framer-motion/dist/framer-motion'
import React from 'react';
import styled from 'styled-components';

const StyledSectionHeader = styled(motion.h3)`
    font-family: 'Montserrat', sans-serif;
    font-size: 3em;
    font-weight: bold;
    font-weight: 600;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    color: #efefef;
    display: block;
    margin-top: 50px;
    @media (max-width: 1300px) {
        font-size: 1.7em;
    }
}
`;

class SectionHeader extends React.Component {
  render() {
      return (
          <StyledSectionHeader
                id={this.props.id}
                initial={{ opacity: 0.2 }}
                whileInView={{ opacity: 1 }}
                viewport={{ once: true }}
                >
            {this.props.title}
          </StyledSectionHeader>
      );
    }
}

export default SectionHeader;