import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet";
import {Trans, useTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import GoogleAnalytics from '../GoogleAnalytics';
import Contact from './Contact';
import Navigation from './Navigation';
import RadioPlayer from './RadioPlayer';
import Social from './Social';

const Wrapper = styled.div`
    padding-top: 150px;
    padding-bottom: 150px;
}
`;

const BackLink = styled.a`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em;
    font-weight: bold;
    font-weight: 300;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    float: left;
    margin-top: 200px;
    margin-left: 16%;
    padding-bottom: 150px;
}
`;

const ContactWrapper = styled.div`
    margin-top: 200px;
}
`;

const SocialWrapper = styled.div`
    margin-top: 200px;
}
`;

function RadioPage({togglePlay, isPlaying}) {
    const {t, i18n} = useTranslation();
    ReactPixel.init('1576304659568289');
    ReactPixel.pageView();

    return (
        <Wrapper>
            <Helmet>
                <html lang={i18n.language}/>
                <meta charSet="utf-8"/>
                <title>Culture Beat Radio</title>
                <link rel="canonical" href={"https://www.culturebeatclub.com/" + i18n.language + "/mix"}/>
                <meta name="description" content={t('Special Mixes For Radio Energy')}/>
                <meta property="og:title" content={t('Culture Beat Radio')}/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content={"https://www.culturebeatclub.com/images/pages/mix.png"}/>
                <meta property="og:url" content={"https://www.culturebeatclub.com/" + i18n.language + "/mix"}/>
                <meta property="og:description" content={t('Special Mixes For Radio Energy')}/>
                <meta property="og:site_name" content="Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria"/>
            </Helmet>
            <GoogleAnalytics/>
            <Navigation/>
            <RadioPlayer togglePlay={togglePlay} isPlaying={isPlaying}/>
            <ContactWrapper><Contact/></ContactWrapper>
            <SocialWrapper><Social/></SocialWrapper>
            <BackLink>
                <FontAwesomeIcon size="lg" icon={faChevronLeft}/>
                <Link to="/"><Trans>Back</Trans></Link>
            </BackLink>
        </Wrapper>
    );
}

export default RadioPage;