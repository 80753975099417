import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react';
import {Helmet} from "react-helmet";
import {Trans, useTranslation} from "react-i18next";
import {Link, useLocation} from 'react-router-dom';
import styled from "styled-components";
import Contact from './components/Contact';
import Footer from './components/Footer';
import SectionDetails from './components/SectionDetails';
import SectionHeader from './components/SectionHeader';

const Wrapper = styled.div`

`

const Section = styled.section`
    padding-top: 80px;
`;

const BackLink = styled.a`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em;
    font-weight: bold;
    font-weight: 300;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    float: left;
    margin-top: 200px;
    margin-left: 16%;
    padding-bottom: 150px;
`;

function App() {

    const location = useLocation();
    const {t, i18n} = useTranslation();

    return (
        <Wrapper>
            <Helmet>
                <html lang={i18n.language}/>
                <meta charSet="utf-8"/>
                <title>{t('Page Not Found 404')}</title>
                <meta name="robots" content="noindex"/>
            </Helmet>
            <Section id={"404"}>
                <SectionHeader title={<Trans>PAGE NOT FOUND 404</Trans>}/>
                <SectionDetails title={<Trans>The page you are looking for is not available.</Trans>}/>
                <Contact/>
            </Section>
            <Contact/>
            <Footer/>
            <BackLink>
                <FontAwesomeIcon size="lg" icon={faChevronLeft}/>
                <Link to="/"><Trans>Back</Trans></Link>
            </BackLink>
        </Wrapper>
    )
}

export default App;
