import {motion} from 'framer-motion/dist/framer-motion'
import React from 'react';
import styled from 'styled-components';

const StyledSectionHeader = styled(motion.div)`
    font-family: "Lora","serif";
    font-size: 2.3em;
    display: block;
    font-style: italic;
    font-weight: 500;
    color: #c1c1c1;
    margin-top: -60px;
    @media (max-width: 1300px) {
        font-size: 1.4em;
        margin-top: -30px;
    }
`;

class SectionHeader extends React.Component {
    render() {
        return (
            <StyledSectionHeader
                initial={{opacity: .2}}
                whileInView={{opacity: 1}}
                viewport={{once: true}}
            >
                {this.props.title} {this.props.year}
            </StyledSectionHeader>
        );
    }
}

export default SectionHeader;