export const images2Bg = [
  {
    src: "/images/menu/bg/5.jpg",
    original: "/images/menu/bg/2.jpg",
    width: 300,
    height: 594,
    caption: "Culture Beat Menu Photo",
  },{
    src: "/images/menu/bg/6.jpg",
    original: "/images/menu/bg/3.jpg",
    width: 300,
    height: 594,
    caption: "Culture Beat Menu Photo",
  },{
    src: "/images/menu/bg/7.jpg",
    original: "/images/menu/bg/4.jpg",
    width: 300,
    height: 594,
    caption: "Culture Beat Menu Photo",
  },
];