import React from "react"
import styled, { keyframes } from 'styled-components';
import Typewriter from 'typewriter-effect';
import { useTranslation, Trans } from "react-i18next";

class TypeComingUpNext extends React.Component {
  render() {

      return (
          <Typewriter
            options={{
              strings: ["Coming Up Next:"],
              autoStart: true,
              loop: true,
              pauseFor: 2500
            }}
          />
      );
    }
}

export default TypeComingUpNext;