import React from 'react';
import {Trans} from "react-i18next";
import styled from 'styled-components';

const Wrapper = styled.div`
    margin-top: 100px;
    position: relative;
    display: block;
    height: 300px;
    @media (max-width: 1000px) {
        height: 700px;
    }
`

const Info = styled.div`
    position: absolute;
    display: block;
    width: 30%;
    height: 300px;
    left: 20%;
    text-align: left;
    @media (max-width: 1000px) {
        width: 60%;
    }
`

const Map = styled.div`
    position: absolute;
    display: block;
    width: 32%;
    height: 300px;
    border: 1px solid white;
    left: 51%;
    @media (max-width: 1000px) {
        width: 60%;
        top: 380px;
        left: 20%;
    }
`

const Header = styled.h5`
    font-size: 1em;
    color: #ffffff;
    font-family: 'Montserrat';
    margin-top: 0px;
`

const Description = styled.h5`
    font-size: 1em;
    margin-bottom: 55px;
`

class Contact extends React.Component {

    render() {
        return (
            <Wrapper>
                <Info>
                    <Header><Trans>Address</Trans>:</Header>
                    <Description><Trans>Address Line</Trans>.</Description>
                    <Header>Email:</Header>
                    <Description>culturebeatevents@gmail.com</Description>
                    <Header><Trans>Phone</Trans>:</Header>
                    <Description>+359 88 727 0777</Description>
                </Info>
                <Map>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11731.673185766407!2d23.319339!3d42.684273!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x96d5eb0d4a4f9eb0!2sCulture%20Beat%20Club!5e0!3m2!1sen!2sbg!4v1677239386634!5m2!1sen!2sbg"
                        style={{border: "0"}}
                        allowfullscreen=""
                        loading="lazy"
                    ></iframe>
                </Map>
            </Wrapper>

        );
    }
}

export default Contact;