import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import styled from "styled-components";
import ClubEvent from "./ClubEvent";
import events from "./events.json";

const Wrapper = styled.div`
    width: 100%;

    &:hover {
        opacity: 0.8;
        cursor: pointer;
    }
`;

const fetchEvents = async (setApiEvents) => {
    const response = await fetch("https://events.lepaff.workers.dev");
    const events = await response.json();
    setApiEvents(events);
};

const ClubEvents = ({ isMain }) => {
    const [apiEvents, setApiEvents] = useState([]);
    const { i18n } = useTranslation();
    const today = new Date();
    const validEvents = events.filter(
        (event) => new Date(event.utfDate) > today && event.image !== undefined
    );

    useEffect(() => {
        fetchEvents(setApiEvents);
    }, []);

    return validEvents.map((event, index) => (
        <Wrapper key={index}>
            <Link to={`/${i18n.language}/events/${event.date}`}>
                <ClubEvent event={event} index={index} />
            </Link>
        </Wrapper>
    ));
};

export default ClubEvents;