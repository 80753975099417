import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet";
import {Trans, useTranslation, withTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Navigation from './Navigation';

const Wrapper = styled.div`
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    padding-top: 150px;
    padding-bottom: 150px;
    display: block;
    width: 99%;
}
`;

const Header = styled.header`
    font-size: 2em;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
`;

const Paragraph = styled.p`
    font-size: 1.5em;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
`;

const BackLink = styled.a`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em;
    font-weight: bold;
    font-weight: 300;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    float: left;
    margin-top: 200px;
    margin-left: 16%;
    padding-bottom: 150px;
`;

function GalleryPage() {
    const {t, i18n} = useTranslation();
    ReactPixel.init('1576304659568289');
    ReactPixel.pageView();

    return (
        <Wrapper>
            <Helmet>
                <html lang={i18n.language}/>
                <meta charSet="utf-8"/>
                <title>{t('PRIVACY POLICY')}</title>
                <meta name="description" content={t("Privacy Policy")}/>
                <link rel="canonical" href={"https://www.culturebeatclub.com/" + i18n.language + "/policy"}/>
                <meta property="og:title" content={t('PRIVACY POLICY')}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={"https://www.culturebeatclub.com/" + i18n.language + "/policy"}/>
                <meta property="og:description" content={t('Privacy Policy')}/>
                <meta property="og:site_name" content="Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria"/>
            </Helmet>
            <Navigation/>
            <Header>
                Политика за защита на личните данни и за поверителност
            </Header>
            <Paragraph>
                Настоящата политика за защита на личните данни и за поверителност е неразделна част от
                Общите условия на „Кълчър Бийт“ ООД и обхваща въпросите, свързани с личните данни, вкл.
                каква информация събираме като Администратор на лични данни, как я използваме и какви
                права имат потребителите в тази връзка.
            </Paragraph>
            <Paragraph>
                „Кълчър Бийт“ ООД има качеството администратор на лични данни съгласно Регламент (ЕС)
                2016/679 (наричан по-долу за краткост „Регламент“).
                Идентификация на администратора:
                Кълчър Бийт ООД,
                регистрирано в Търговския регистър към Агенция по вписванията с ЕИК: 202925231, ИН по
                ДДС: BG202925231, седалище и адрес на управление: гр. София, пл. България 1, НДК, ет 2, Управител:
                Мартин Георгиев
            </Paragraph>
            <Paragraph>
                „Кълчър Бийт“ ООД третира като лични данни всяка информация,коятоидентифицира
                конкретнофизическо лицеили която се отнасядофизическо лице, чрез която същото може да
                бъде идентифицирано. Обработването на лични данни представлява действие или съвкупност
                от действия, които могат да се извършат по отношение на личните данни с автоматични или
                други средства.
            </Paragraph>
            <Header>
                I. Как събираме информация за Вас?
            </Header>
            <Paragraph>
                1. Ние събираме лични данни, след изрично съгласие на лицето, за което се отнасят, както и в
                случаите, когато се сключва договор - за нуждите на неговото изпълнение или в случай, че
                съществува нормативно или административно изискване за това. Когато се регистрирате на
                нашия сайт или използвате някоя от формите, Вие ни предоставяте определена информация
                доброволно, която ние обработваме и съхраняваме. Тази информация включва: име, презиме,
                фамилия, имейл адрес, телефонен номер, IP адрес и всяка друга информация, която ни
                предоставяте. Възможно е да предпочетем да намалим обема на данните, които съхраняваме и
                обработваме, съобразно целите на обработката.
            </Paragraph>
            <Paragraph>
                2. В случай, че решите да извършите плащане посредством сайта, ние събираме данни за
                използваното от Вас платежно средство, например номер на кредитната Ви карта, информация
                за връзка (адрес и телефонен номер).
            </Paragraph>
            <Paragraph>
                3. При свързване на вашия профил с профила си във Facebook или Google или с други услуги
                на трети страни, ние получаваме и информацията от тези профили (например приятели или
                контакти). Информацията, която получаваме от тези услуги, зависи от настройките и от
                декларациите за поверителност, така че всяко лице следва да провери какви са те.
            </Paragraph>
            <Paragraph>
                4. Също така, ние получаваме техническа информация, когато използвате сайта. Всеки път,
                когато използвате сайта или друга интернет услуга, системата създава и записва определена
                информация автоматично. Ето някои от категориите информация, която събираме:
            </Paragraph>
            <Paragraph>
                a/ Данни в регистрационните файлове.Когато използвате сайта, нашите сървъри записват
                информация (&quot;данни от дневника&quot; или “log data”), включително информация, която браузърът ви
                автоматично изпраща при посещение на уеб сайт или мобилно приложение, когато го
                използвате. Тези данни от дневника включват адреса на интернет протокола, адреса и
                активността на посещаваните от вас уеб сайтове или приложения, търсения, тип и настройки на
                браузъра, дата и час на заявката ви, как сте използвали сайта, данните за &quot;бисквитките&quot; и
                данните за устройствата. Ако искате да получите повече подробности за информацията, която
                ние събираме – свържете се с нас чрез посочените данни за контакт.
            </Paragraph>
            <Paragraph>
                б/ Данни за &quot;бисквитките&quot;. Ние също така използваме &quot;бисквитки&quot; (малки текстови
                файлове,
                изпратени от вашия компютър всеки път, когато посещавате нашия сайт) или подобни
                технологии за улавяне на данни. Когато използваме &quot;бисквитки&quot; или други подобни технологии,
                ние използваме &quot;бисквитки&quot; на сесията (продължават, докато затворите браузъра си) или
                постоянните &quot;бисквитки&quot; (които продължават, докато вие или браузърът ви ги изтрият).
                Например, ние използваме &quot;бисквитки&quot;, за да съхраняваме вашите настройки, така че не е
                нужно да ги настройвате всеки път, когато посещавате сайта. Някои от &quot;бисквитките&quot;, които
                използваме, са свързани с профила ви (включително информация за вас, като например имейл
                адреса, който сте ни дали), а други &quot;бисквитки&quot; не са. За по-подробна информация за това как
                използваме &quot;бисквитките&quot;, моля, прегледайте правилата ни за &quot;бисквитките&quot;.
            </Paragraph>
            <Paragraph>
                в/ Информация за устройството. В допълнение към данните в дневника, ние събираме
                информация за устройството, чрез което използвате нашия сайт, включително тип устройство,
                операционна система, настройки, уникални идентификатори на устройства и данни за сривове,
                които ни помагат да разберем, когато нещо се повреди. Дали събираме част или цялата
                информация, често зависи от типа на устройството, което използвате и от настройките му.
                Например има различни типове информация в зависимост от това дали използвате Mac или
                компютър или iPhone или телефон с Android. За да научите повече за това, каква информация
                прави вашето устройство достъпни за нас, моля, проверете и правилата на производителя на
                устройството или доставчика на софтуер.
            </Paragraph>
            <Header>
                II. Какво правим с информацията, която събираме. Цели и срок на обработката:
                Цели
            </Header>
            <Paragraph>
                1. „Кълчър бийт“ ООД обработва и съхранява личнитe данни, посочени по-горе единствено с
                цел изпълнение на договорните си задължения и по-точно обработване на заявките на своите
                потребители, както и за следните цели:
            </Paragraph>
            <Paragraph>
                а/ На основание чл. 6, т.1, буква „б” от Регламента - за осъществяване на преддоговорни
                отношения;
            </Paragraph>
            <Paragraph>
                б/ На основание чл. 6, т.1, буква „б” от Регламента - за изпълнение на вече възникнали
                договорни задължения.
            </Paragraph>
            <Paragraph>
                в/ На основание чл. 6, ал. 1, буква „а” и чл. 7 от Регламента – за неперсонализирана реклама;
            </Paragraph>
            <Paragraph>
                г/ На основание чл. 6, ал. 1, буква „а” и чл. 7 от Регламента – за персонализирана реклама;
            </Paragraph>
            <Paragraph>
                д/ На основание чл. 22, ал. 2, буква „в”, чл. 6, ал. 1, буква „а” и чл. 7 от Регламента – за
                извършване на персонализирана оценка на информация;
            </Paragraph>
            <Paragraph>
                е/ На основание чл. 6, ал. 1, буква „е” – за маркетингови цели.
            </Paragraph>
            <Paragraph>
                ж/ На основание чл. 6, ал. 1, буква „е” от Регламента – за ретаргетинг във връзка с целите на
                маркетинга, ремаркетинга или оптимизацията;
            </Paragraph>
            <Paragraph>
                2. Извън случаите по т. 1, б. „а” и „б”, субектът на данни може да възрази срещу посочените
                цели на обработване в свободен текст на посочените в настоящата политика данни за контакт,
                като след възражението, обработването ще бъде преустановено.
                Срок
                Данните се съхраняват и обработват докато акаунтът на потребителя е действащ и 1 година
                след деактивирането или изтриването му, както и докато има нужда от нея за предоставяне на
                услугите ни. В случай, че лицето направи съответната заявка, информацията се унищожава
                незабавно.
                Получатели на личните ви данни
                Предоставените от Вас данни са ни нужни, за да изпълним своите преддоговорни или
                договорни задължения спрямо Вас. Не предоставяме тези данни на трети лица, с изключение
                на
            </Paragraph>
            <Paragraph>
                – обработващия плащането доставчик на платежни услуги (банка, PayPal, ePay) и
            </Paragraph>
            <Paragraph>
                – нашия счетоводител и
                При незавършена поръчка оставяме въведените по нея данни за 30 дни и си запазваме правото
                да Ви информираме периодично за нейния статус, съответно за Вашите опции вследствие на
                това. При завършена поръчка и изпълнен от наша страна договор на основание чл. 6, ал. 1,
                букви (а) и (б) от ОРЗЛД съхраняваме всички данни по правоотношението до изтичане на
                предвидения в чл. 12 от Закона за счетоводството ( ЗСч ) 10-годишен срок.
            </Paragraph>
            <Paragraph>
                Обработването на личните Ви данни може да бъде извършено без Ваше съгласие, само ако по
                силата на закон сме длъжни да предоставим на държавни органи и/или на трети лица
                информация, съдържаща лични данни, при което в изпълнението на императивни разпоредби
                на закона ще бъдат предоставяни само лични данни с минимално необходимото съдържание
                за всеки конкретен случай.
                Когато ни пишете или ни пращате имейл, ние съхраняваме данните Ви за периода, необходим
                за обработване на искането Ви, както и за да отговорим на Ваши последващи въпроси. Няма да
                споделяме тази информация с трети лица без Вашето изрично съгласие!
            </Paragraph>
            <Paragraph>
                Права, които може да упражните във връзка с вашите лични данни:
                Всички права се упражняват, а съответните искания и уведомления във връзка с правата на
                субектите на данни, се депозират на e-mail culturebeatevents@gmail.com или по пощата на
                адреса за кореспонденция, посочен по-горе. Исканията се правят по начин, който позволява да
                се идентифицира самоличността на заявителя. По отношение на някои права, възможно е да
                бъдат приложими технически възможности за упражняването им. При всички случаи,
                администраторът следва да отговори на искането или да се произнесе по отношение на
                обявеното право на предоставения във формата за контакт адрес или е-мейл, в срок от един
                месец от получаването й.
                Според Общия регламент за защита на личните данни субектът на данни има право на:
            </Paragraph>
            <Paragraph>
                •Информираност(във връзка с обработването на личните му данни от администратора);
                Когато има риск за нарушение сигурността на личните Ви данни, администраторът е длъжен да
                Ви уведоми за естеството на нарушението и какви мерки са предприети за отстраняването му,
                както и дали е уведомен надзорният орган за нарушението.
            </Paragraph>
            <Paragraph>
                •Достъп до собствените си лични данни и право на оттегляне на съгласието за
                обработка. Като субект на лични данни имате право да поискате потвърждение дали се
                обработват личните Ви данни и ако това е така - да получите достъп до данните си и следната
                информация: за каква цел се обработват данни, какви лични данни, получателите на данни,
                срокът на обработване. Исканията за достъп трябва да бъдат изготвени в писмен/електронен
                вид и да бъдат адресирани до администратора. Също така, имате право по всяко време да
                оттеглите съгласието си за обработка на личните ви данни.
            </Paragraph>
            <Paragraph>
                •Коригиране (ако данните са неточни). Като субект на лични данни имате право да поискате
                коригиране на личните си данни, които са неточни/неактуални. За тази цел трябва да подадете
                отделно искане. На искането Ви ще бъде отговорено от страна на администратора по следния
                начин – писмено, на предоставения е-мейл адрес.
            </Paragraph>
            <Paragraph>
                •Изтриване на личните данни (право „да бъдеш забравен“). Като субект на лични данни
                имате право да „бъдете забравен”, т.е. да поискате личните Ви данни да бъдат изтрити без
                ненужно забавяне т.е. администраторът да заличи личните ви данни от всички системи и
                записи, където те се съхраняват, включително да уведоми всички трети лица/обработващи
                лични данни, на които е предоставил данните. Искане за изтриване може да се подаде на
                основанията, предвидени в Регламента, вкл. при наличието на някое от следните основания:
                личните данни повече не са необходими за целите, за които са били събрани; когато сте
                оттеглил своето съгласие; когато сте възразил срещу обработването, когато обработването е
                незаконосъобразно; когато личните данни трябва да бъдат изтрити с цел спазването на правно
                задължение по правото на Съюза или правото на държава членка, което се прилага спрямо
                администратора; когато личните данни са били събрани във връзка с предлагането на услуги на
                информационното общество. Администраторът може да откаже да заличи личните данни на
                основанията, посочени в Регламента - когато обработването на конкретните данни е с цел:
                упражняване правото на свобода на изразяване и информация; изпълняване на правно
                задължение или задача от обществен интерес или упражняване на публична власт; за целите
                на общественото здраве; архивиране за цели в обществен интерес, научноизследователски
                исторически изследвания или статистически цели; или установяване, упражняване или
                защитата на правни претенции. Обръщаме внимание, че изтриването на лични данни вероятно
                ще доведе до невъзможност да изпълним договорните си задължения към Вас.
            </Paragraph>
            <Paragraph>
                •Ограничаване на обработванетоот страна на администратора или обработващия лични
                данни. Като субект на лични данни имате право да поискате от администратора на личните Ви
                данни да ограничи обработването им. Ограничаването се допуска в следните случаи: - когато
                считате, че личните Ви данни не са точни, като в този случай ограничаването е за срок,
                необходим на администратора да провери точността; - когато обработването на личните Ви
                данни е неправомерно, но не желаете те да бъдат изтрити, а желаете само да бъде ограничено
                използването им; - когато администраторът не се нуждае повече от личните Ви данни за целите
                на обработването, но вие, като субект на данните, ги изисквате за установяването,
                упражняването или защитата на правни претенции; - когато сте възразил срещу обработването
                в очакване на проверка дали законните основания на администратора имат преимущество пред
                интересите ви. За целта, при наличие на някое от горните условия, следва да подадете искане.
            </Paragraph>
            <Paragraph>
                •Преносимост на личните данни, вкл. между отделните администратори. Субектът на
                данните има право на преносимост - да получи личните данни, които го засягат и които той е
                предоставил на администратор, в структуриран, широко използван и пригоден за машинно
                четене формат и има правото да прехвърли тези данни на друг администратор без
                възпрепятстване от администратора, на когото личните данни са предоставени, когато
                обработването е основано на съгласие или на договорно задължение и обработването се
                извършва по автоматизиран начин. Когато упражнява правото си на преносимост на данните,
                субектът на данните има право да получи и пряко прехвърляне на личните данни от един
                администратор към друг, когато това е технически осъществимо.
            </Paragraph>
            <Paragraph>
                •Възражение спрямо обработванетона негови лични данни. Като субект на лични данни
                имате право да възразите срещу обработването на личните ви данни по всяко време, вкл.
                когато е за целите на директния маркетинг. Администраторът следва да се мотивира дали
                приема възражението, респ. защо продължава да обработва личните данни, ако отхвърли
                възражението.
            </Paragraph>
            <Paragraph>
                • Субектът на данни има право ида не бъде обект на решение, основаващо се единствено
                на автоматизирано обработване, включващо профилиране, което поражда правни
                последствия за субекта на данните или по подобен начин го засяга в значителна степен.
                Субектът на данни има право по всяко време да оспори автоматизираното решение.
            </Paragraph>
            <Paragraph>
                •Право на защитапо съдебен или административен ред, в случай, че правата на субекта на
                данни са били нарушени. Като субект на лични данни имате право на жалба срещу
                обработването на личните Ви данни или неспазване на правата ви във връзка със защитата на
                лични данни пред компетентния надзорен орган - Комисия за защита на личните данни, адрес:
                гр. София 1592, бул. „Проф. Цветан Лазаров” № 2 (www.cpdp.bg). Също така, лице, което е
                претърпяло имуществени или неимуществени вреди в резултат на нарушение на настоящиите
                правила, има право да получи обезщетение от администратора или обработващия лични данни
                за нанесените вреди.
            </Paragraph>
            <Paragraph>
                Сигурност
            </Paragraph>
            <Paragraph>
                Ние сме предприели множество технически, правни и организационни мерки за защита на
                личните данни на всяко едно лице. Възможно е да ползваме услугите на трети лица, които се
                явяват обработващи лични данни за горепосочените цели на обработката. Тези лица
                обработват личните данни по наше възлагане и са задължени да спазват действащите
                разпоредби за защита на личните данни. Тези лица са внимателно подбрани от нас и имат
                достъп единствено до данни, които са им необходими за предоставянето на услугите, с които
                са ангажирани и в рамките на изявеното към нас съгласие. В случай, че тези лица са извън ЕС
                и не изпълняват необходимите изисквания на GDPR, въз основа на статута му на нормативен
                акт, ние ще гарантираме защитата на личните данни чрез договорни или други правни
                инструменти. Също така, е възможно личните данни да бъдат предоставени на държавни или
                общински органи, които осъществяват различен вид контрол в рамките на закона.
            </Paragraph>
            <Paragraph>
                Реклама
            </Paragraph>
            <Paragraph>
                С потвърждаване на заявката за регистрация на акаунт потребителят дава своето изрично
                съгласие за обработка личните му данни за една или повече от следните цели:
            </Paragraph>
            <Paragraph>
                а/ Включване на оценката на потребителя и неговото мнение в маркетингови проучвания чрез
                електронни методи – по e-mail или месинджър.
            </Paragraph>
            <Paragraph>
                б/ Получаване на електронни съобщения за услуги и др. рекламни съобщения на всички
                притежавани устройства.
            </Paragraph>
            <Paragraph>
                в/ Получаване на персонализирана реклама, която е съобразена с предпочитанията на
                потребителя. Персонализацията се извършва въз основа на оценка на данните за поведението
                на потребителя;
            </Paragraph>
            <Paragraph>
                г/ Получаване на съобразени с поведението на потребителя и относими към неговите
                предпочитания персонализирани търговски предложения по имейл, поща или месинджър. За
                тази цел данните за потреблението на потребителя въз основа на неговото поведение,
                участието му в рекламни акции, както и използването на сайта могат да бъдат предмет на
                анализ и прогноза за интересите на потребителя.
            </Paragraph>
            <Paragraph>
                д/ Получаване на неперсонализирана реклама. Потребителите ще получават информация и за
                актуални услуги, инициативи и др. рекламни съобщения.
                Потребителите могат във всеки един момент да подадат до администратора посредством
                формата за контакт заявление за отказ от получаване на рекламни съобщения.
                Нюзлетър
                Можете да се абонирате за нашия нюзлетър, като попълните формуляра на нашия сайт. За
                тази цел се нуждаем от Вашия имейл адрес и съгласието Ви да Ви пращаме съответните
                съобщения.
                След като се регистрирате за нашия нюзлетър/мейлинг, ще ви изпратим потвърдително
                съобщение с линк, с чиято помощ да потвърдите регистрацията.
                Можете да се откажете от абонамента си за нашия нюзлетър по всяко време. Моля, изпратете
                отказа си на следния електронен адрес: culturebeatevents@gmail.com или използвайте бутона
                „отпиши ме“. Веднага след това ще заличим данните Ви във връзка с изпращането на нашия
                нюзлетър.
            </Paragraph>
            <Paragraph>
                Декларация
            </Paragraph>
            <Paragraph>
                В процеса на обработка на лични данни „Кълчър Бийт“ ООД спазва принципите на
                Европейското и национално законодателство, свързани със защитата на личните данни на
                лицата. Прилагайки пакет от организационни, технически и правни мерки, ние се стремим да
                гарантираме високо ниво на сигурност на личните данни, защита срещу нерегламентирана
                обработка, унищожаване или повреждане.
                Декларираме, че личните данни, които събираме, ще бъдат използвани единствено за цели,
                изложени до тук.
            </Paragraph>
            <Paragraph>
                Контакти
            </Paragraph>
            <Paragraph>
                1.Данни за контакт на длъжностното лице по защита на личните данни:
                Кълчър Бийт ООД, седалище и адрес на управление: гр. София, пл. България 1, НДК, ет 2
                ЕИК: 202925231, Управител: Мартин Георгиев
            </Paragraph>
            <Paragraph>
                2. Имате право на жалба пред Комисия за защита на личните данни на адрес: гр.София 1592,
                бул.„Проф.Цветан Лазаров” №2 или електронно по реда на Закона за електронния документ и
                електронните удостоверителни услуги. Повече информация може да намерите на сайта на
                Комисията https://www.cpdp.bg.
            </Paragraph>
            <BackLink>
                <FontAwesomeIcon size="lg" icon={faChevronLeft}/>
                <Link to="/"><Trans>Back</Trans></Link>
            </BackLink>
        </Wrapper>
    );
}

export default withTranslation()(GalleryPage);