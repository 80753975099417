import React from 'react';
import {Trans, useTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
    margin-top: 50px;
    padding-bottom: 300px;
    width: 99%;
    font-family: 'Montserrat', sans-serif;
    font-size: .8em;
    font-weight: 300;
    color: #efefef;
    -webkit-font-smoothing: antialiased;
    @media (max-width: 1200px) {
        padding-bottom: 400px;
    }
}
`;

const StyledFooter = styled.div`
    display: block;
    height: 50px;
    clear: both;
    padding: 30px;
}
`;

const Legal = styled.div`
    display: block;
}
`;

const CompanyInfo = styled.div`
    display: block;
    width: 45%;
    float: right;
    text-align: right;
    padding: 30px;
    @media (max-width: 1200px) {
        width: 85%;
        float: left;
        text-align: center;
    }
`;

const CookiesInfo = styled.div`
    display: block;
    width: 45%;
    float: left;
    text-align: left;
    padding: 30px;
    @media (max-width: 1200px) {
        width: 85%;
        width: q00%;
        text-align: center;
    }
}
`;

const Paragraph = styled.p`
    width: 100%;
}
`;

const ParagraphBold = styled.p`
    font-size: 1em;
    width: 100%;
    font-weight: 700;
}
`;

function Copyright() {

    const {t, i18n} = useTranslation();
    return (
        <Wrapper>
            <StyledFooter>
                <Trans>Copyright</Trans>
            </StyledFooter>
            <Legal>
                <CookiesInfo>
                    <ParagraphBold>
                        <Link to={"/" + i18n.language + "/policy"}>
                            Политика за поверителност
                        </Link>
                    </ParagraphBold>
                    <ParagraphBold>
                        <Link to={"/" + i18n.language + "/conditions"}>
                            Общи условия
                        </Link>
                    </ParagraphBold>
                    <ParagraphBold>
                        <Link to={"/" + i18n.language + "/cookies"}>
                            Политика бисквитки
                        </Link>
                    </ParagraphBold>
                </CookiesInfo>
                <CompanyInfo>
                    <ParagraphBold>
                        Кълчър Бийт ООД
                    </ParagraphBold>
                    <Paragraph>
                        седалище и адрес на управление: гр. София, пл. България 1, НДК, ет 2
                    </Paragraph>
                    <Paragraph>
                        регистрирано в Търговския регистър към Агенция по вписванията
                    </Paragraph>
                    <Paragraph>
                        ЕИК: 202925231, ИН по ДДС: BG202925231
                    </Paragraph>
                    <Paragraph>
                        Т ел. + 359 88 727 0777, e-mail: culturebeatevents@gmail.com
                    </Paragraph>
                </CompanyInfo>
            </Legal>
        </Wrapper>
    );
}

export default Copyright;