import React from 'react';
import {Trans} from "react-i18next";
import styled from 'styled-components';
import {A11y, Autoplay, Navigation, Pagination, Scrollbar} from 'swiper';
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'
import {Swiper, SwiperSlide} from 'swiper/react/swiper-react'

const Wrapper = styled.div`
    margin-top: 300px;
    margin-bottom: 200px;
    width: 100%;
    display: block;
    @media (max-width: 1200px) {
        margin-top: 100px;
        margin-bottom: 50px;
    }
`;

const Title = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: 4.2em;
    font-weight: bold;
    font-weight: 600;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    color: #efefef;
    display: block;
    position: absolute;
    top: 200px;
    left: 200px;
    z-index: 50;
    @media (max-width: 1100px) {
        font-size: 1.2em;
        max-width: 40%;
        top: 120px;
        left: 100px;
        text-align: left;
    }
`

const Description = styled.p`
    font-family: "Lora";
    font-style: italic;
    color: #cccccc;
    font-weight: 300;
    font-size: 2em;
    display: block;
    position: absolute;
    top: 300px;
    left: 200px;
    max-width: 800px;
    height: 150px;
    text-align: left;
    z-index: 50;
    @media (max-width: 1100px) {
        font-size: 1em;
        max-width: 40%;
        top: 200px;
        left: 100px;
    }
`;

const Slide = styled(SwiperSlide)`
    width: 100%;
`

const SlideOne = styled.div`
    width: 100%;
    background-position: center;
    background-size: cover;
    height: 800px;
    opacity: 0.5;
    background-image: url("/images/carousel_1.jpeg");
    @media (max-width: 1100px) {
        height: 500px;
    }
`

const SlideTwo = styled.div`
    width: 100%;
    background-position: center;
    background-size: cover;
    height: 800px;
    opacity: 0.5;
    background-image: url("/images/carousel_2.jpeg");
    @media (max-width: 1100px) {
        height: 500px;
    }
`

const SlideThree = styled.div`
    width: 100%;
    background-position: center;
    background-size: cover;
    height: 800px;
    opacity: 0.5;
    background-image: url("/images/carousel_3.jpeg");
    @media (max-width: 1100px) {
        height: 500px;
    }
`

const SlideFour = styled.div`
    width: 100%;
    background-position: center;
    background-size: cover;
    height: 800px;
    opacity: 0.5;
    background-image: url("/images/carousel_4.jpeg");
    @media (max-width: 1100px) {
        height: 500px;
    }
`

class Carousel extends React.Component {
    render() {
        return (
            <Wrapper>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
                    loop={true}
                    spaceBetween={0}
                    navigation={{clickable: true}}
                    pagination={{clickable: true}}
                    autoplay={{
                        delay: 5000,
                        disableOnInteraction: false
                    }}
                >
                    <Slide>
                        <Title><Trans>CULTURE BEAT</Trans></Title>
                        <Description><Trans>Culture Beat Description</Trans></Description>
                        <SlideOne/>
                    </Slide>
                    <Slide>
                        <Title><Trans>SUMMER TERRACE</Trans></Title>
                        <Description><Trans>Summer Terrace Beat Description</Trans></Description>
                        <SlideTwo/>
                    </Slide>
                    <Slide>
                        <Title><Trans>CORPORATE EVENTS</Trans></Title>
                        <Description><Trans>Corporate Event Reservation Description</Trans></Description>
                        <SlideThree/>
                    </Slide>
                    <Slide>
                        <Title><Trans>PERSONAL EVENTS</Trans></Title>
                        <Description><Trans>Personal Event Reservation Description</Trans></Description>
                        <SlideFour/>
                    </Slide>
                </Swiper>
            </Wrapper>
        );
    }
}

export default Carousel;