import React from 'react';
import styled from "styled-components";
import { Trans } from "react-i18next";
import Rating from 'react-rating';

const Wrapper = styled.div`
    &:hover {
        opacity: .8;
        cursor: pointer;
    }
`

const StyledRatingGradient = styled.div`
    display: block;
    width: 18%;
    height: 20px;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,1));
    position: fixed;
    bottom: 30px;
    z-index: 1000;
    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const StyledRating = styled.a`
    font-family: "Lora",serif;
    font-size: 1em;
    font-weight: 600;
    text-align: left;
    -webkit-font-smoothing: antialiased;
    color: #c1c1c1;
    position: fixed;
    display: block;
    background-color: black;
    bottom: 0;
    width: 18%;
    height: 30px;
    z-index: 1000;
    @media (max-width: 1200px) {
        width: 100%;
        text-align: center;
    }
`;

const StyledRatingSpan = styled.span`
    position: relative;
    bottom: 5px;
    left: 10px;
`;

const StyledRatingStars = styled(Rating)`
    position: relative;
    left: 15px;
`;

const StyledRatingImage = styled.img`
    width: 20px;
`;

function RateUs() {

  return (
      <Wrapper>
        <StyledRatingGradient />
        <StyledRating
          title="Rate Us On Google"
          target="_blank"
          href="https://g.page/r/CbCeT0oN69WWEB0/review">
          <StyledRatingSpan><Trans>Rate Us On Google</Trans></StyledRatingSpan>
          <StyledRatingStars
              initialRating={3.6}
              emptySymbol={<StyledRatingImage src="/images/star-empty.png" className="icon" />}
              fullSymbol={<StyledRatingImage src="/images/star-full.png" className="icon" />}
              readonly
          />
        </StyledRating>
      </Wrapper>
    )
}
export default RateUs;