import {faBars, faXmark} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {motion} from 'framer-motion/dist/framer-motion'
import React, {useEffect, useLayoutEffect, useState} from 'react';
import {Trans, useTranslation} from "react-i18next";
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import styled from 'styled-components';
import logo from '../media/images/culture-beat-logo-nav-black-space.png';
import '../i18n';

const StyledNavigation = styled.nav`
    font-family: 'Montserrat', sans-serif;
    font-size: .9em;
    font-weight: bold;
    font-weight: 600;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    color: white;
    display: block;
    position: fixed;
    background: black;
    height: 60px;
    width: 100%;
    top: 0px;
    z-index: 100;
    line-height: 70px;
    text-align: left;
}
`;

const StyledLogo = styled.img`
    position: fixed;
    top: 25px;
    left: 5%;
    z-index: 10;
    width: 230px;
`;

const DesktopWrapper = styled.div`
    position: absolute;
    right: 15%;
`

const StyledHashLink = styled(HashLink)`
    margin-left: 50px;
`

const StyledRouterHashLink = styled(Link)`
    margin-left: 50px;
`

const MobileNavigationButton = styled(FontAwesomeIcon)`
    position: absolute;
    right: 5%;
    top: 20px;
    font-size: 26px;

    &:hover {
        opacity: .8;
        cursor: pointer;
    }
`

const MobileWrapper = styled(motion.div)`
    margin-top: 60px;
    background-color: black;
    display: block;
`

const MobileHashLink = styled(Link)`
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    position: relative;
    display: block;
    padding-left: 30px;

    &:hover {
        opacity: .8;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.1);
    }
`

const LanguageSelector = styled(motion.div)`
    position: fixed;
    right: 5px;
    top: 200px;
    display: block;
    width: 30px;
    height: 100px;
    padding: 0px;
    line-height: 50px;
    border-left: 1px solid #c1c1c1;
    border-top: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
    border-radius: 5px;
`

const StyledLanguageLink = styled.div`
    display: inline-block;
    width: 25px;
    padding-left: 5px;
    height: 30px;
    margin: 0px;
    line-height: 30px;

    &:hover {
        opacity: .8;
        cursor: pointer;
        background: rgba(255, 255, 255, 0.3);
    }
`

const StyledGradient = styled.div`
    display: block;
    width: 100%;
    height: 10px;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    position: fixed;
    top: 60px;
`;

const Logo = () => <StyledLogo src={logo} alt="Culture Beat Club, Sofia" title="Culture Beat Club, Sofia"/>

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);

    useLayoutEffect(() => {

        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }

        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);

    }, []);
    return size;
}

function ShowWindowDimensions(props) {
    const [width, height] = useWindowSize();
    return <span>Window size: {width} x {height}</span>;
}

function ShowMobileNavigation() {
    const [width, height] = useWindowSize();
    return <span>Window size: {width} x {height}</span>;
}

function ShowNavigation() {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    const [isMain, setIsMain] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const location = useLocation();

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, []);

    const listenToScroll = () => {
        let heightToHideFrom = 150;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        if (winScroll > heightToHideFrom) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    useEffect(() => {
        if (location.pathname.includes("/events")
            || location.pathname.includes("/mix")
            || location.pathname.includes("/gallery")
            || location.pathname.includes("/reserve")
            || location.pathname.includes("/policy")
            || location.pathname.includes("/conditions")
            || location.pathname.includes("/cookies")
            || location.pathname.includes("/contact")) {
            setIsMain(false);
        } else {
            setIsMain(true);
        }
    }, [location.pathname])


    const [width, height] = useWindowSize();
    const [showMobileNavigation, setShowMobileNavigation] = useState("hideMobileNavigation");

    const showMobileNavigationTemplate = () => {
        setShowMobileNavigation("showMobileNavigation")
    }

    const hideMobileNavigationTemplate = () => {
        setShowMobileNavigation("hideMobileNavigation")
    }

    const {t, i18n} = useTranslation();


    const navigate = useNavigate();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        if (!location.pathname.includes("/bg/") && !location.pathname.includes("/en/")) {
            navigate("/" + i18n.language + "/");
        } else if (lng === "bg") {
            navigate(location.pathname.replace("/en/", "/bg/"));
        } else if (lng === "en") {
            navigate(location.pathname.replace("/bg/", "/en/"));
        }
    };

    if (showMobileNavigation === "showMobileNavigation") {
        return <StyledNavigation>
            {isVisible && isMain ? (
                <Link to="/"></Link>
            ) : (
                <Link to="/"><Logo/></Link>
            )}
            <MobileWrapper
                initial={{opacity: 0, y: -200}}
                whileInView={{opacity: 1, y: 0}}
                viewport={{once: true}}
                exit={{y: -200, opacity: 0}}
            >
                <MobileHashLink onClick={() => hideMobileNavigationTemplate()}
                                to={"/" + i18n.language + "/events"}><Trans>EVENTS</Trans></MobileHashLink>
                <MobileHashLink onClick={() => hideMobileNavigationTemplate()} to={"/" + i18n.language + "/mix"}><Trans
                    t={t}>MIX</Trans></MobileHashLink>
                <MobileHashLink onClick={() => hideMobileNavigationTemplate()}
                                to={"/" + i18n.language + "/gallery"}><Trans t={t}>HIGHLIGHTS</Trans></MobileHashLink>
                <MobileHashLink onClick={() => hideMobileNavigationTemplate()}
                                to={"/" + i18n.language + "/reserve"}><Trans t={t}>RESERVE</Trans></MobileHashLink>
                <MobileHashLink onClick={() => hideMobileNavigationTemplate()}
                                to={"/" + i18n.language + "/contact"}><Trans t={t}>CONTACT</Trans></MobileHashLink>
            </MobileWrapper>
            <MobileNavigationButton
                size="lg"
                icon={faXmark}
                onClick={() => hideMobileNavigationTemplate()}/>
            <LanguageSelector>
                <StyledLanguageLink onClick={() => changeLanguage("bg")}>BG</StyledLanguageLink>
                <StyledLanguageLink onClick={() => changeLanguage("en")}>EN</StyledLanguageLink>
            </LanguageSelector>
        </StyledNavigation>
    } else {
        return <StyledNavigation>
            <MobileWrapper>

                {isVisible && isMain ? (
                    <Link to="/"></Link>
                ) : (
                    <Link to="/"><Logo/></Link>
                )}
                <MobileNavigationButton
                    size="lg"
                    icon={faBars}
                    onClick={() => showMobileNavigationTemplate()}/>
            </MobileWrapper>
            <LanguageSelector>
                <StyledLanguageLink onClick={() => changeLanguage("bg")}>BG</StyledLanguageLink>
                <StyledLanguageLink onClick={() => changeLanguage("en")}>EN</StyledLanguageLink>
            </LanguageSelector>

            <StyledGradient/>
        </StyledNavigation>
    }
}

class Navigation extends React.Component {
    render() {
        return (
            <ShowNavigation/>
        );
    }
}

export default Navigation;

