import React, {useState, useEffect} from 'react';
import styled, {keyframes} from 'styled-components';
import SectionHeader from './SectionHeader';
import SectionDetails from './SectionDetails';
import SingleClubEventDate from './clubevents/SingleClubEventDate';
import ComingUpSingleEvent from './comingup/ComingUpSingleEvent';
import ReservationEvent from './ReservationEvent';
import Contact from './Contact';
import Footer from './Footer';
import Social from './Social';
import Copyright from './Copyright';
import Navigation from './Navigation';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {Link, useParams, useLocation, useNavigate} from 'react-router-dom';
import {Trans, withTranslation, useTranslation,} from "react-i18next";
import Helmet from "react-helmet";
import events from "./clubevents/events.json";
import GoogleAnalytics from '../GoogleAnalytics';
import ReactPixel from 'react-facebook-pixel';

const Wrapper = styled.div`
    padding-top: 150px;
    padding-bottom: 150px;
`;

const BackLink = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.3em;
    font-weight: bold;
    font-weight: 300;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    float: left;
    margin-top: 100px;
    margin-left: 16%;
    padding-bottom: 150px;

    &:hover {
        opacity: .8;
        cursor: pointer;
    }
`;

const StyledCover = styled.img`
    position: relative;
    top: -50px;
    display: block;
    width: 50%;
    margin: 0 auto;
    @media (max-width: 1200px) {
        width: 90%;
    }
`

const NyeInfo = styled.img`
    position: relative;
    top: 50px;
    padding-bottom: 50px;
    display: block;
    width: 50%;
    margin: 0 auto;
    @media (max-width: 1200px) {
        width: 90%;
    }
`

const StyledGradient = styled.div`
    display: block;
    height: 10px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
    position: fixed;
    bottom: 70px;
    width: 100%;
    z-index: 1000;
    @media (max-width: 1200px) {
        width: 100%;
        bottom: 50px;
    }
`;

const StyledBack = styled.a`
    font-family: "Lora";
    font-size: 1.7em;
    font-weight: 600;
    text-align: center;
    padding: 10px;
    -webkit-font-smoothing: antialiased;
    color: #c1c1c1;
    position: fixed;
    display: block;
    background-color: black;
    bottom: 0;
    height: 50px;
    z-index: 1000;
    width: 100%;
    @media (max-width: 1200px) {
        padding: 0px;
        font-size: 1.4em;
        text-align: center;
    }

    &:hover {
        opacity: .9;
        cursor: pointer;
    }
`;

const StyledBackSpan = styled.a`
    opacity: .6;
`;

function findEventById(date) {
    return events.find((event) => {
        return event.date === date;
    })
}

function Capitalize(str) {
    const string = str.charAt(0).toUpperCase() + str.slice(1);
    return <Trans>{string}</Trans>;
}

function GetParams() {
    return useParams();
}

function GetEvent() {
    return events.find(e => e.date === GetParams().date);
}

function EventPage() {
    const location = useLocation();
    const {t, i18n} = useTranslation();
    ReactPixel.init('1576304659568289');
    ReactPixel.pageView();

    const navigate = useNavigate();

    function NavigateBackToEvents() {
        return navigate("../../../" + i18n.language + "/events", {relative: "path"});
    }

    const event = GetEvent();
    const [isPastEvent, setIsPastEvent] = useState(true);
    const today = new Date();

    if (event === undefined || event === null) {
        navigate("..", {relative: "path"});
    } else {
        const eventDate = new Date(event.utfDate);

        useEffect(() => {
            if (today < eventDate) {
                setIsPastEvent(false)
            }
        }, [eventDate]);

        const [isVisible, setIsVisible] = useState(false);

        useEffect(() => {
            window.addEventListener("scroll", listenToScroll);
            return () =>
                window.removeEventListener("scroll", listenToScroll);
        }, []);

        const listenToScroll = () => {
            let heightToHideFrom = 500;
            const winScroll = document.body.scrollTop ||
                document.documentElement.scrollTop;
            if (winScroll > heightToHideFrom) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };

        return (
            <Wrapper>
                <GoogleAnalytics/>
                {event.image ? (
                    <StyledCover src={event.image} alt={event.name + " " + event.date}
                                 title={event.name + " " + event.date}/>
                ) : null}
                <Helmet>
                    <meta property="og:image" content={"https://www.culturebeatclub.com" + event.image}/>
                    <meta property="og:title" content={t(event.name)}/>
                    <meta property="og:url"
                          content={"https://www.culturebeatclub.com/" + i18n.language + "/event/" + event.date}/>
                    <meta property="og:description" content={t(event.name + ", " + event.dj + ", " + event.date)}/>
                    <meta property="og:site_name" content="Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria"/>
                    <meta property="og:type" content="website"/>
                    <html lang={i18n.language}/>
                    <meta charSet="utf-8"/>
                    <title>{t(event.name)}</title>
                    <meta name="description" content={t(event.name + " " + event.dj + " " + event.date)}/>
                    <link rel="canonical"
                          href={"https://www.culturebeatclub.com/" + i18n.language + "/event/" + event.date}/>
                    <script type="application/ld+json">
                        {JSON.stringify({
                            "@context": "https://schema.org",
                            "@type": "Event",
                            "name": event.name + " with " + event.dj,
                            "startDate": event.utfDate,
                            "endDate": event.utfEndDate,
                            "eventAttendanceMode": "https://schema.org/MixedEventAttendanceMode",
                            "eventStatus": "https://schema.org/EventScheduled",
                            "location": [
                                {
                                    "@type": "Place",
                                    "url": "https://www.culturebeatclub.com/",
                                    "name": "Culture Beat Club",
                                    "address": {
                                        "@type": "PostalAddress",
                                        "streetAddress": "1 Bulgaria Square, National Palace Of Culture",
                                        "addressLocality": "Sofia",
                                        "postalCode": "1000",
                                        "addressCountry": "BG"
                                    }
                                }],
                            "image": [
                                event.image
                            ],
                            "description": event.name + " with " + event.dj,
                            "performer": {
                                "@type": "Person",
                                "name": event.dj
                            },
                            "organizer": {
                                "@type": "Organization",
                                "name": "Culture Beat Club",
                                "url": "https://www.culturebeatclub.com/"
                            }
                        })}
                    </script>
                </Helmet>
                <Navigation/>
                <SectionHeader title={event.name}/>
                <SectionDetails title={event.dj}/>
                {event.type === "NYE_PARTY" ? (
                    <NyeInfo src="/images/events/december/nye.png" alt={event.name + " " + event.date}
                             title={event.name + " " + event.date}/>
                ) : null}
                <ComingUpSingleEvent event={event}/>
                {isPastEvent ? (
                    <SectionHeader title={<Trans>Past Event</Trans>}/>
                ) : null}
                {event.type === "FULLY_BOOKED" && !isPastEvent ? (
                    <SectionHeader title={<Trans>Fully Booked</Trans>}/>
                ) : null}
                {event.type !== "FULLY_BOOKED" && !isPastEvent ? (
                    <ReservationEvent event={event}/>
                ) : null}
                {isVisible ? (
                    <StyledBack onClick={() => NavigateBackToEvents()}>
                        <StyledBackSpan>
                            <FontAwesomeIcon size="lg" icon={faChevronLeft}/>
                            <Link to="/"> <Trans>BACK TO EVENTS</Trans></Link>
                        </StyledBackSpan>
                        <StyledGradient/>
                    </StyledBack>
                ) : null}
                <Copyright/>
            </Wrapper>
        );
    }
}

export default withTranslation()(EventPage);