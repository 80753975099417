export const imagesBg = [
 {
     src: "/images/menu/bg/2.jpg",
     original: "/images/menu/bg/2.jpg",
     width: 300,
     height: 594,
     caption: "Culture Beat Menu Photo",
   },{
     src: "/images/menu/bg/3.jpg",
     original: "/images/menu/bg/3.jpg",
     width: 300,
     height: 594,
     caption: "Culture Beat Menu Photo",
   },{
     src: "/images/menu/bg/4.jpg",
     original: "/images/menu/bg/4.jpg",
     width: 300,
     height: 594,
     caption: "Culture Beat Menu Photo",
   },{
       src: "/images/menu/bg/5.jpg",
       original: "/images/menu/bg/2.jpg",
       width: 300,
       height: 594,
       caption: "Culture Beat Menu Photo",
     },{
       src: "/images/menu/bg/6.jpg",
       original: "/images/menu/bg/3.jpg",
       width: 300,
       height: 594,
       caption: "Culture Beat Menu Photo",
     },{
       src: "/images/menu/bg/7.jpg",
       original: "/images/menu/bg/4.jpg",
       width: 300,
       height: 594,
       caption: "Culture Beat Menu Photo",
     },{
         src: "/images/menu/bg/8.jpg",
         original: "/images/menu/bg/2.jpg",
         width: 300,
         height: 594,
         caption: "Culture Beat Menu Photo",
       },{
         src: "/images/menu/bg/9.jpg",
         original: "/images/menu/bg/3.jpg",
         width: 300,
         height: 594,
         caption: "Culture Beat Menu Photo",
       },{
         src: "/images/menu/bg/10.jpg",
         original: "/images/menu/bg/4.jpg",
         width: 300,
         height: 594,
         caption: "Culture Beat Menu Photo",
       },
];