import React, {useEffect, useState} from 'react';
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet";
import {Trans, useTranslation} from "react-i18next";
import styled from "styled-components";
import Carousel from './components/Carousel';
import ClubEvents from './components/clubevents/ClubEvents';
import events from "./components/clubevents/events.json";
import ComingUp from './components/comingup/ComingUp';
import Contact from './components/Contact';
import Copyright from './components/Copyright';
import Footer from './components/Footer';
import HeaderVideo from './components/HeaderVideo';
import Highlights from './components/Highlights';
import Menu from './components/Menu';
import Navigation from './components/Navigation';
import RadioPlayer from './components/RadioPlayer';
import Reservation from './components/Reservation';
import SectionDetails from './components/SectionDetails';
import SectionHeader from './components/SectionHeader';
import Social from './components/Social';
import GoogleAnalytics from './GoogleAnalytics';
import RateUs from './RateUs';
import StructuredData from './sturctureddata/BarAndPub.json';

const Wrapper = styled.div`

`

const Section = styled.section`
    padding-top: 80px;
`;

const StyledRatingSpan = styled.span`
    position: relative;
    bottom: 5px;
    right: 30px;
`;

function Main({togglePlay, isPlaying}) {
    const {t, i18n} = useTranslation();
    ReactPixel.init('1576304659568289');
    ReactPixel.pageView();

    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", listenToScroll);
        return () =>
            window.removeEventListener("scroll", listenToScroll);
    }, []);

    const listenToScroll = () => {
        let heightToHideFrom = 200;
        const winScroll = document.body.scrollTop ||
            document.documentElement.scrollTop;
        if (winScroll > heightToHideFrom) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    };

    return (
        <Wrapper>
            <Helmet>
                <html lang={i18n.language}/>
                <meta charSet="utf-8"/>
                <title>"Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria"</title>
                <meta name="description" content="Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria"/>
                <meta property="og:title" content='Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria'/>
                <meta property="og:type" content="website"/>
                <meta property="og:image" content={"https://www.culturebeatclub.com/images/pages/main.png"}/>
                <meta property="og:url" content={"https://www.culturebeatclub.com/" + i18n.language}/>
                <meta property="og:description" content='Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria'/>
                <meta property="og:site_name" content="Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria"/>
                <script type="application/ld+json">
                    {JSON.stringify(StructuredData)}
                </script>
            </Helmet>
            <GoogleAnalytics/>
            <Navigation/>
            <HeaderVideo/>
            <Section id={"events"}>
                <SectionHeader title={<Trans>UPCOMING EVENTS</Trans>}/>
                <SectionDetails title={<Trans>November</Trans>} year=" 2024"/>
                <ClubEvents clubEvents={events} isMain="false"/>
                <ComingUp/>
            </Section>
            <Section id={"highlights"}>
                <SectionHeader title={<Trans>HIGHLIGHTS</Trans>}/>
                <SectionDetails title={<Trans>Highlights From Last Month</Trans>}/>
                <Highlights/>
            </Section>
            <Section id={"music"}>
                <RadioPlayer togglePlay={togglePlay} isPlaying={isPlaying}/>
            </Section>
            <Section id={"carousel"}>
                <Carousel/>
            </Section>
            <Section id={"reservation"}>
                <Reservation/>
            </Section>
            <Section id={"menu"}>
                <SectionHeader title={<Trans>MENU</Trans>}/>
                <SectionDetails title={<Trans>Our Menu</Trans>}/>
                <Menu/>
            </Section>
            <Section id={"contact"}>
                <SectionHeader title={<Trans>CONTACT</Trans>}/>
                <SectionDetails title={<Trans>Contact Us</Trans>}/>
                <Contact/>
            </Section>
            <Section id={"footer"}>
                <Footer/>
            </Section>
            <Section id={"social"}>
                <Social/>
            </Section>
            <Section id={"copyright"}>
                <Copyright/>
            </Section>
            {isVisible ? (
                <RateUs/>
            ) : null}
        </Wrapper>
    )
}

export default Main;