export const images1En = [
  {
    src: "/images/menu/en/2.jpg",
    original: "/images/menu/en/2.jpg",
    width: 300,
    height: 594,
    caption: "Culture Beat Menu Photo",
  },{
    src: "/images/menu/en/3.jpg",
    original: "/images/menu/en/3.jpg",
    width: 300,
    height: 594,
    caption: "Culture Beat Menu Photo",
  },{
    src: "/images/menu/en/4.jpg",
    original: "/images/menu/en/4.jpg",
    width: 300,
    height: 594,
    caption: "Culture Beat Menu Photo",
  },
];