import {faSquareFacebook, faSquareInstagram, faSquareYoutube} from '@fortawesome/free-brands-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {motion} from 'framer-motion/dist/framer-motion'
import React from 'react';
import styled from 'styled-components';
import tikTokLogo from '../media/images/tiktok_logo.png';
import tripAdvisorLogo from '../media/images/tripadvisor_logo.png';


const Wrapper = styled.div`

}
`;

const StyledFooter = styled(motion.div)`
    font-family: 'Montserrat', sans-serif;
    font-size: 3em;
    font-weight: 600;
    color: #efefef;
    display: block;
    margin-top: -60px;
}
`;

const StyledImageLogo = styled.img`
    width: 53px;
    height: 53px;
    border-radius: 5px;
    position: relative;
    top: 9px;
    left: 1px;
    margin-right: 10px;

    &:hover {
        opacity: .8;
    }
`

const StyledIcon = styled(FontAwesomeIcon)`
    margin-left: 0px;
    margin-right: 10px;

    &:hover {
        opacity: .8;
    }
`

const StyledParagraph = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    font-weight: 500;
    margin-top: 20px;
    padding: 5px;

    &:hover {
        opacity: .8;
    }
`

class Social extends React.Component {
    render() {
        return (
            <Wrapper>
                <StyledFooter
                    initial={{opacity: 0.2}}
                    whileInView={{opacity: 1}}
                    viewport={{once: true}}
                >
                    <a
                        title="Facebook"
                        target="_blank"
                        href="https://www.facebook.com/CultureBeatClub/">
                        <StyledIcon size="lg" icon={faSquareFacebook}/>
                    </a>
                    <a
                        title="Instagram"
                        target="_blank"
                        href="https://www.instagram.com/culturebeatclub/?igshid=YmMyMTA2M2Y%3D">
                        <StyledIcon size="lg" icon={faSquareInstagram}/>
                    </a>
                    <a
                        title="TikTok"
                        target="_blank"
                        href="https://www.tiktok.com/@culturebeatclub">
                        <StyledImageLogo src={tikTokLogo}/>
                    </a>
                    <a
                        title="Tripadvisor"
                        target="_blank"
                        href="https://www.tripadvisor.com/Attraction_Review-g294452-d7084640-Reviews-Culture_Beat_Club-Sofia_Sofia_Region.html">
                        <StyledImageLogo src={tripAdvisorLogo}/>
                    </a>
                    <a
                        title="Youtube"
                        target="_blank"
                        href="https://www.youtube.com/@culturebeatclub7872">
                        <StyledIcon size="lg" icon={faSquareYoutube}/>
                    </a>
                </StyledFooter>
            </Wrapper>
        );
    }
}

export default Social;