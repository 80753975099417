import React from  'react';
import styled, { keyframes } from 'styled-components';
import TypeComingUpNext from './TypeComingUpNext'
import { motion } from 'framer-motion/dist/framer-motion'
import events from "../clubevents/events.json";
import Countdown, { zeroPad } from 'react-countdown';
import { useTranslation, Trans } from "react-i18next";
import SingleClubEventDate from '../clubevents/SingleClubEventDate';


const Wrapper = styled(motion.div)`
    margin-top: 100px;
    margin-bottom: 50px;
`;

const StyledHeader = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: 4.2em;
    font-weight: bold;
    font-weight: 600;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    color: #efefef;
    display: block;
    @media (max-width: 1300px) {
       font-size: 1.6em;
    }
`

const EventDj = styled.p`
    font-family: "Lora";
    font-style: italic;
    color: #cccccc;
    font-weight: 300;
    font-size: 2em;
    line-height: 0px;
    @media (max-width: 1300px) {
       font-size: 1.6em;
    }
`;

const EventDate = styled.div`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8em;
    font-weight: bold;
    font-weight: 600;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    color: #efefef;
    display: block;
    @media (max-width: 1300px) {
       font-size: 1.4em;
    }
`;

const DateWrapper = styled.div`
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.4);
    display: inline-block;
    width: 80px;
    height: 140px;
    margin-left: 20px;
    margin-right: 20px;
    clear: both;
    @media (max-width: 1200px) {
        margin-left: 10px;
        margin-right: 10px;
        width: 50px;
        height: 100px;
    }
`;

const Value = styled.p`
    line-heigh: 40px;
    height: 20px;
    margin-bottom: 0px;
    padding-bottom: 0px;
`;

const ValueName = styled.p`
    font-size: .3em;
    font-family: "Lora";
    color: #ffffff;
    padding-top: 25px;
`;

const Separator = styled.span`
    position: relative;
    top: -20px;
    @media (max-width: 1200px) {
        top: -30px;
    }
`;

const renderer = ({ days, hours, minutes, seconds }) => {
    return <EventDate>
            <EventDate>
                <DateWrapper>
                    <Value>{zeroPad(days)}</Value>
                    <ValueName><Trans>Days</Trans></ValueName>
                </DateWrapper>

                <Separator>:</Separator>

                <DateWrapper>
                    <Value>{zeroPad(hours)}</Value>
                    <ValueName><Trans>Hours</Trans></ValueName>
                </DateWrapper>

                <Separator>:</Separator>

                <DateWrapper>
                    <Value>{zeroPad(minutes)}</Value>
                    <ValueName><Trans>Minutes</Trans></ValueName>
                </DateWrapper>

                <Separator>:</Separator>

                 <DateWrapper>
                    <Value>{zeroPad(seconds)}</Value>
                    <ValueName><Trans>Seconds</Trans></ValueName>
                </DateWrapper>
           </EventDate>
           </EventDate>;
};

class ComingUp extends React.Component {
  render() {
      return (
        <Wrapper
            initial={{ opacity: 0,  y: -200 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition= {{ delay: 0.3, delayChildren: 0.5 }}>
            <SingleClubEventDate
                month={new Date(this.props.event.utfDate).toLocaleDateString("en", { month: 'long' })}
                weekday={new Date(this.props.event.utfDate).toLocaleDateString("en", { weekday: 'long' })}
                day={new Date(this.props.event.utfDate).getUTCDate()}
            />
            <Countdown date={this.props.event.utfDate} renderer={renderer}></Countdown>
        </Wrapper>
      );
  }
}

export default ComingUp;