import {faChevronLeft} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import React from 'react';
import ReactPixel from 'react-facebook-pixel';
import {Helmet} from "react-helmet";
import {Trans, useTranslation, withTranslation} from "react-i18next";
import {Link} from 'react-router-dom';
import styled from 'styled-components';
import Navigation from './Navigation';

const Wrapper = styled.div`
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    padding-top: 150px;
    padding-bottom: 150px;
    display: block;
    width: 99%;
}
`;

const Header = styled.header`
    font-size: 2em;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
`;

const Paragraph = styled.p`
    font-size: 1.5em;
    width: 80%;
    margin: 0 auto;
    padding: 20px;
`;

const BackLink = styled.a`
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1em;
    font-weight: bold;
    font-weight: 300;
    letter-spacing: -1px;
    -webkit-font-smoothing: antialiased;
    float: left;
    margin-top: 200px;
    margin-left: 16%;
    padding-bottom: 150px;
`;

function GalleryPage() {
    const {t, i18n} = useTranslation();
    ReactPixel.init('1576304659568289');
    ReactPixel.pageView();

    return (
        <Wrapper>
            <Helmet>
                <html lang={i18n.language}/>
                <meta charSet="utf-8"/>
                <title>{t('PRIVACY POLICY')}</title>
                <meta name="description" content={t("Privacy Policy")}/>
                <link rel="canonical" href={"https://www.culturebeatclub.com/" + i18n.language + "/policy"}/>
                <meta property="og:title" content={t('PRIVACY POLICY')}/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={"https://www.culturebeatclub.com/" + i18n.language + "/policy"}/>
                <meta property="og:description" content={t('Privacy Policy')}/>
                <meta property="og:site_name" content="Culture Beat Club (Кълчър Бийт) - Sofia, Bulgaria"/>
            </Helmet>
            <Navigation/>
            <Header>


                ОБЩИ УСЛОВИЯ НА „КЪЛЧЪР БИЙТ“ ООД
            </Header>
            <Paragraph>
                Уважаеми Клиенти,
            </Paragraph>
            <Paragraph>
                Настоящите общи условия са предназначени за регулиране на отношенията между
                „Кълчър бийт” ООД, ЕИК 202925231, със седалище и адрес на управление: гр. София,
                пл. България 1, НДК, ет 2, наричано по-долу за краткост ДОСТАВЧИК, и клиентите,
                наричани по-долу ПОЛЗВАТЕЛИ, на сайта https://www.culturebeatclub.com/.
                Преди да използвате сайта, прочетете внимателно настоящата информация и
                съдържащите се по-долу общи условия. Тя представя изискванията на българското и
                европейското законодателство и по-специално на Закона за защита на потребителите,
                Закона за предоставяне на цифрово съдържание и цифрови услуги и за продажба на
                стоки, Закона за електронната търговия, Регламент /ЕС/ 2016/679 относно защитата на
                физическите лица във връзка с обработването на лични данни, Директива 2011/83/ЕС
                относно правата на потребителите.
            </Paragraph>
            <Paragraph>
                Настоящите общи условия са част от договора между Вас и „Кълчър бийт” ООД, както
                и от споразумението относно употребата на сайта www.culturebeatclub.com. Всеки
                посетител, независимо от това дали е регистриран или не, използвайки сайта, показва
                съгласие с настоящите условия. В случай на несъгласие с тях, моля преустановете
                незабавно ползването на приложението.
                Посочената по-долу информация е част от търговските условия между „Кълчър бийт”
                ООД, като собственик на сайта www.culturebeatclub.com, представляващ платформа за
                резервации в заведението „Кълчър бейт“ и потребителите на сайта, относно ползването
                му и предоставените на него възможности за сключване на договори от разстояние.
            </Paragraph>
            <Paragraph>
                ПОТРЕБИТЕЛСКА ИНФОРМАЦИЯ
            </Paragraph>
            <Paragraph>
                ОБЩИ РАЗПОРЕДБИ
            </Paragraph>
            <Paragraph>
                1. По смисъла на настоящите Общи условия:
            </Paragraph>
            <Paragraph>
                1.1. „Ползвател” е всеки, който е заредил сайта www.culturebeatclub.com
            </Paragraph>
            <Paragraph>
                1.2. „Регистриран ползвател” е всеки ползвател, който е персонализирал своето
                присъствие в сайта, посредством въвеждане на потребителско име и парола.
            </Paragraph>
            <Paragraph>
                1.3. „Потребител” е всеки ползвател/регистриран ползвател, който е физическо лице и
                който сключва договор за ползване на приложението за лични нужди.
            </Paragraph>
            <Paragraph>
                1.4. „Поръчка” е предложение, отправено от регистриран или нерегистриран
                потребител, за резервация при условията предвидени по-долу.
            </Paragraph>
            <Paragraph>
                1.5. “Доставчик” е:
            </Paragraph>
            <Paragraph>
                а/Наименование на Доставчика: „Кълчър бийт” ООД
            </Paragraph>
            <Paragraph>
                б/Седалище и адрес на управление: Република България, гр. София, пл. България
                1, НДК, ет 2
            </Paragraph>
            <Paragraph>
                в/ Адрес за упражняване на дейността и адрес за отправяне на жалби от
                потребители: гр. София, пл. България 1, НДК, ет 2
            </Paragraph>
            <Paragraph>
                г/ Данни за кореспонденция: e-mail culturebeatevents@gmail.com
            </Paragraph>
            <Paragraph>
                д/ Вписване в българския търговски регистър: ЕИК 202925231
            </Paragraph>
            <Paragraph>
                СТРАНИ
            </Paragraph>
            <Paragraph>
                2. Собственик на сайта www.culturebeatclub.com е „Кълчър бийт” ООД, ЕИК
                202925231, което дружество е доставчик на услугите, предлагани чрез сайта.
            </Paragraph>
            <Paragraph>
                3. Страна по настоящите условия са ползвателите и потребителите по смисъла на т.
                1.2, т. 1. 3. и т. 1. 4.
            </Paragraph>
            <Paragraph>
                4. Сайтът www.culturebeatclub.com предлага информация за цените и услугите и
                възможности за резервация на места в заведението „Кърчър бийт“ на дееспособни лица
                над 18 години.
            </Paragraph>
            <Paragraph>
                4.1 Приемайки настоящите общи условия, потребителите декларират, че са на възраст
                на или над 18 години и че са дееспособни.
            </Paragraph>
            <Paragraph>
                4.2 В случай, че сте на възраст под 18 години и/или сте недееспособни, моля не
                ползвайте приложението.
            </Paragraph>
            <Paragraph>
                4.3 Доставчикът си запазва правото да ограничи достъпа на определени потребители
                до сайта и/или предоставяните на него услуги при положение, че съществуват данни
                доказващи, че тяхната възраст е под 18 години и/или, че същите са недееспособни.
                ЕЛЕКТРОНЕН ОБМЕН И ТЪРГОВСКИ СЪОБЩЕНИЯ
            </Paragraph>
            <Paragraph>
                5. Когато посещават сайта www.culturebeatclub.com или изпращат съобщения под
                формата на e-mail, Ползвателите комуникират с Доставчика в електронен вид. За
                нуждите от осъществяване на връзка с Ползвателите, Доставчикът си запазва правото
                да използва подобни електронни методи на комуникация. Във връзка с това,
                Ползвателите изразяват своето съгласие за използването на такъв вид комуникация и
                заявяват, че предадените посредством нея споразумения, уведомления, данни и пр.
                отговарят на законовите изисквания, приложими по отношение на тях в писмена
                форма.
            </Paragraph>
            <Paragraph>
                6. Ползвателите, които не са Потребители се съгласяват, че Доставчикът на сайта
                www.culturebeatclub.com има право да изпраща по всяко време електронни съобщения
                към тях, включително и бюлетин или предложения за предоставяне на услуги.
            </Paragraph>
            <Paragraph>
                6.1 Доставчикът няма право да изпраща непоискани търговски съобщения без
                предварителното разрешение на Потребителя.
            </Paragraph>
            <Paragraph>
                6.2 Доставчикът няма право да изпраща непоискани търговски съобщения на лицата
                по чл. 6, ал. 2 от Закона за електронната търговия.
            </Paragraph>
            <Paragraph>
                6.3 Доставчикът не носи отговорност за търговски съобщения и непоискани
                търговски съобщения, изпращани от трети лица (в това число рекламодатели
                сътрудници и др.), независимо от това, дали тези съобщения са свързани или не с
                употребата на приложението и предоставените на него услуги.
            </Paragraph>
            <Paragraph>
                РЕЗЕРВАЦИЯ. ЦЕНА. НАЧИН НА ПЛАЩАНЕ
            </Paragraph>
            <Paragraph>
                7. Доставчикът предоставя на Ползвателите възможност да направят резервация на
                места в заведението „Кълчър бийт“, при спазване на настоящите условия
            </Paragraph>
            <Paragraph>
                8. Преди да решите да направите резервация, моля, запознайте се с описанието на
                услугите, предлагани чрез сайта.
            </Paragraph>
            <Paragraph>
                9. 1. В хода на процедурата по резервация следва да попълните всички полета с
                изключение на „Допълнителна информация“, което е опционално.
            </Paragraph>
            <Paragraph>
                9. 2. Резервация е допустима при заявка за минимум 3 лица.
            </Paragraph>
            <Paragraph>
                9. 3. При отправяне на заявката за резервация системата начислява резервационна такса
                /капаро/, която задължително трябва да бъде заплатена, за да бъде резервацията
                валидна. Заплащането на капарото се извършва чрез някои от способите, посочени в
                приложението.
            </Paragraph>
            <Paragraph>
                9. 4. Ползвателите трябва задължително да спазят посочения от тях час на
                резервацията. Резервацията се пази от „Кълчър бийт“ ООД в продължение на 20
                минути след посочения час, след което се анулира.
            </Paragraph>
            <Paragraph>
                9. 5. В случай, че Ползвателят установи, че не е в състояние да спази часа на
                резервацията, той може да уведоми „Кълчър бийт“ ООД на посочения в сайта телефон
                поне 1 /един/ час преди часа на резервацията. В този случай при възможност /наличие
                на свободни места/, „Кълчър бийт“ ООД ще промени часа на резервацията в рамките на
                същия ден. Резервация не може да бъде прехвърляна за друг ден. За избягване на
                всякакви неясноти се уточнява, че „Кълчър бийт“ ООД няма задължение да промени
                часа на направена резервация в случай, че няма обективна възможност за това.
            </Paragraph>
            <Paragraph>
                9. 6. Резервации се приемат най-късно до 18 часа на същия ден.
            </Paragraph>
            <Paragraph>
                9. 7. След изпращане на заявка за резервация „Кълчър бийт“ ООД изпраща автоматично
                генериран илейм, че същата е направена. След обработка на резервацията се изпраща
                изричен потвърждаващ имейл, че резервацията е приета. Ако такъв потвърждаващ мейл
                не е изпратен, то резервацията не се счита за приета, а направеното плащане се
                възстановява автоматично в сметката на Ползвателя.
            </Paragraph>
            <Paragraph>
                9. 8. В случай, че направена резервация не бъде спазена, независимо от причините за
                това /извън случая, когато тя е валидно отложена за по-късен час при условията на т. 9.
                5./, заплатеното при резервацията капаро се губи.
            </Paragraph>
            <Paragraph>
                10. Цените на нашите Услуги и на резервационната такса може да се променят
                периодично.
            </Paragraph>
            <Paragraph>
                ПРИЕМАНЕ НА УСЛОВИЯ И СКЛЮЧВАНЕ НА ДОГОВОР
            </Paragraph>
            <Paragraph>
                11. 1. Ползвателите приемат настоящите условия както следва:
            </Paragraph>
            <Paragraph>
                11. 2. Ползвателите, които нямат регистрация в приложението, приемат условията
                чрез неговото зареждане и използване на предоставените чрез него услуги.
            </Paragraph>
            <Paragraph>
                11. 3. Регистрираните ползватели приемат настоящите условия, чрез отбелязване на
                полето „съгласен съм с общите ” и натискане на бутона „Продължи”. По този начин
                Ползвателите извършват електронно изявление по смисъла на Закона за електронния
                документ и електронните удостоверителни услуги, по силата на което декларират, че са
                запознати с настоящите условия, приемат ги и се задължават да ги спазват.
            </Paragraph>
            <Paragraph>
                ПРОМЕНИ В УСЛОВИЯТА И СЪДЪРЖАНИЕТО
            </Paragraph>
            <Paragraph>
                12.1.Доставчикът има право да променя едностранно настоящите условия.
            </Paragraph>
            <Paragraph>
                12.2. Всички промени в настоящите условия влизат в сила след изпращане на
                уведомителни електронни съобщения (е-mail) или след публикуването им на сайта.
            </Paragraph>
            <Paragraph>
                12.3. Промените в условията не засягат отношенията между Ползвателя и Доставчика,
                във връзка с валидно направена преди уведомлението резервация.
            </Paragraph>
            <Paragraph>
                12.4 Доставчикът има право по своя преценка да променя едностранно съдържанието
                на сайта, да прави ъпдейти и да подновява базата данни, както и да променя дизайна.
            </Paragraph>
            <Paragraph>
                ОТКАЗ ОТ ДОГОВОРА ОТ ПОТРЕБИТЕЛИ
            </Paragraph>
            <Paragraph>
                14. 1. Право на отказ от договора имат единствено ползватели, които имат качеството
                на Потребители.
            </Paragraph>
            <Paragraph>
                14. 1. Потребител, който е сключил договор за резервация през сайта
                www.culturebeatclub.com, има право да откаже от договора в срок от 14 дни от деня,
                следващ датата на неговото сключване.
                На основание чл.57, т.1 от Закона за защита на потребителите договорът за резервация
                не може да бъде едностранно прекратен, когато услугата е предоставена изцяло преди
                изтичане на срока за отказ и изпълнението е започнало с изричното предварително
                съгласие и потвърждение на потребителя, че знае, че ще загуби правото си на отказ,
                след като договорът бъде изпълнен изцяло.
            </Paragraph>
            <Paragraph>
                14. 2. Упражняването на правото на отказ води до прекратяване на договорните
                отношения между Потребителя и Доставчика, като Доставчикът е длъжен да върне
                получените получените парични средства. За да произведе действие отказът,
                Потребителят е длъжен да информира изрично Доставчика за решението си преди
                изтичане на 14 дневния срок за отказ. Срокът тече от деня, следващ деня на сключване
                на договора от страна на Потребителя. Уведомлението за отказ се изпраща в писмена
                форма на адреса на Доставчика, по електронен път, или по друг подходящ начин на
                посочен от Доставчика адрес.
            </Paragraph>
            <Paragraph>
                14. 3. За упражняване на правото на отказ, Потребителят може да използва стандартния
                формуляр за отказ, представляващ Приложение № 6 към Закона за защита на
                потребителите /Приложение 1Б от Директива 2011/83/ и приложен към настоящите
                общи условия или да заяви решението си по друг начин, но същото трябва да бъде ясно
                и недвусмислено.
            </Paragraph>
            <Paragraph>
                14. 4. При упражняване на правото на отказ, Доставчикът възстановява на Потребителя
                платената от него сума в срок от 14 дни от получаване на уведомлението за отказ, като
                използва същия способ за плащане, който е използвал Потребителят, освен ако
                Потребителят изрично се е съгласил да получи обратно парите си по друг начин.
            </Paragraph>
            <Paragraph>
                КОНФИДЕНЦИАЛНОСТ И ЗАЩИТА НА ЛИЧНИТЕ ДАННИ
            </Paragraph>
            <Paragraph>
                15. 1. Събирането, съхранението и обработването на лични данни се осъществява
                съобразно Политиката на „Кълчър бийт” ООД за поверителност на личните данни,
                която можете да достъпите тук Политиката за поверителност.
            </Paragraph>
            <Paragraph>
                15. 2. С цел сключване и изпълнение на договорите за резервация посредством
                интернет страницата, Доставчикът събира и обработва следната информация относно
                Ползвателите:
            </Paragraph>
            <Paragraph>
                - лично име, бащино име и фамилия
            </Paragraph>
            <Paragraph>
                - актуален адрес (независимо постоянен, настоящ или друг) с цел изпращане на
                уведомления
            </Paragraph>
            <Paragraph>
                - адрес на електронна поща
            </Paragraph>
            <Paragraph>
                - телефонен номер (стационарен, мобилен или друг) за връзка
            </Paragraph>
            <Paragraph>
                - адрес на електронната поща на регистриран потребител
            </Paragraph>
            <Paragraph>
                - наименование на регистриран потребител
            </Paragraph>
            <Paragraph>
                - IP адрес на потребител
            </Paragraph>
            <Paragraph>
                - друга информация, касаеща посещаемостта и техническите параметри на
                приложението (часа и дата на посещение, колко време е продължило ползването на
                приложението, колко време е отнело за зареждане на приложението и всяка страница,
                как точно се ползва, кои продукти и услуги разглеждат и др.)
            </Paragraph>
            <Paragraph>
                15. 3. Информацията се събира посредством технологията cookies, web-маяци и др.
            </Paragraph>
            <Paragraph>
                16.1 Файловете, по настоящия раздел, разположени върху вашия хард диск от интернет
                страницата, не засягат по никакъв начин вашата операционна система, нейните ресурси
                и функционалност, не заплашват по никакъв начин вашия софтуер, хардуер, вашата
                сигурност, и могат да бъдат изтрити по всяко време.
            </Paragraph>
            <Paragraph>
                16.2 Ползвателят може по всяко време да забрани приемането на файловете по
                настоящата точка посредством настройка на своя интернет браузър.
            </Paragraph>
            <Paragraph>
                17. Събраната информация се използва единствено за:
            </Paragraph>
            <Paragraph>
                - Поддръжка и надеждно функциониране на висококачествена интернет страница и
                подобряване нивото на предлаганите услуги;
            </Paragraph>
            <Paragraph>
                - Осигуряване на желаната от Ползвателите информация и сделки;
            </Paragraph>
            <Paragraph>
                - Персонализиране на дейността съгласно профил на купувача;
            </Paragraph>
            <Paragraph>
                - Комуникация между Доставчика и Ползвателите;
            </Paragraph>
            <Paragraph>
                - Извършване на проучвания и подобрения в предлаганите от нас услуги;
            </Paragraph>
            <Paragraph>
                - Установяване и отстраняване на технически нередности;
            </Paragraph>
            <Paragraph>
                - Анализ на поведението на страницата и Ползвателите;
            </Paragraph>
            <Paragraph>
                - Подаване на необходимата трафик информация към рекламодатели.
            </Paragraph>
            <Paragraph>
                18. Доставчикът се задължава да не разкрива лична информация за Ползвателите и да
                не предоставя събраната такава на трети лица, освен когато:
            </Paragraph>
            <Paragraph>
                - е получил изричното съгласие на Ползвателя;
            </Paragraph>
            <Paragraph>
                -информацията е изискана от държавни органи и длъжностни лица, които съгласно
                действащото българско законодателство са оправомощени да изискват и събират
                подобна информация, при спазване на законоустановените процедури;
            </Paragraph>
            <Paragraph>
                - в други случаи, предвидени в закон.
            </Paragraph>
            <Paragraph>
                19. Ползвателите имат право да поискат изтриване на наличните за тях лични данни
                позволяващи идентифицирането им като индивиди. Ползвателите приемат, че това
                може да доведе до забавяне или невъзможност за извършване или изпълнение на
                резервации, като в този случай Доставчикът няма да носи отговорност за неизпълнение
                на поети договорни задължения.
            </Paragraph>
            <Paragraph>
                20. Обработката на събраните данни се извършва в съответствие с разпоредбите на
                настоящите условия и разпоредбите на действащото законодателство на Република
                България и Регламент 2016/679.
            </Paragraph>
            <Paragraph>
                21. 1. Във всеки момент, Доставчикът в сайта www.culturebeatclub.com има право да
                изисква от Ползвателя да се легитимира и да удостовери достоверността на всяко едно
                от обявените по време на регистрацията обстоятелства и лични данни.
            </Paragraph>
            <Paragraph>
                УКАЗАНИЯ КЪМ ПОЛЗВАТЕЛИТЕ И АВТОРСКИ ПРАВА
            </Paragraph>
            <Paragraph>
                22. Сайтът www.culturebeatclub.com, включително всички текстове, файлове, дизайни,
                лога и останалите елементи, от които се изгражда уебсайта, са собственост на „Кълчър
                бийт” ООД. Използваният графичен материал е или собствен на „Кълчър бийт” ООД,
                или е използван графичен материал без лиценз.
                (a) Като използвате Услугите, вие носите отговорност за познаването, разбирането и
                спазването на нашите общи условия. Тези правила не са изчерпателни и „Кълчър бийт”
                ООД си запазва правото да определи какво поведение счита за неподходящо
                използване на сайта и да предприеме такива действия, каквито сметне за подходящи в
                случай на нарушение. „Кълчър бийт” ООД си запазва правото да допълва или променя
                тези правила по всяко време.
            </Paragraph>
            <Paragraph>
                Вие се съгласявате да не използвате Услугите за:
            </Paragraph>
            <Paragraph>
                A. Публикувне, изпращане по имейл, предаване, качване или предоставяне по друг
                начин на достъп до всяко съдържание, което позволява на лица, които не са ползватели
                да получат достъп до информацията на сайта www.culturebeatclub.com.
            </Paragraph>
            <Paragraph>
                Б. Фалшифициране на заглавия или друг вид манипулиране на идентификационни
                данни с цел прикриване на произхода на съдържание, предавано чрез сайта.
            </Paragraph>
            <Paragraph>
                C. да се представяте за друго физическо или юридическо лице, или да заявите
                погрешно вашата идентификация като физическо или юридическо лице.
            </Paragraph>
            <Paragraph>
                Г. Да прекъсвате Услугата или сървърите или мрежите, свързани с Услугата, или да не
                се подчинявате на изискванията, процедурите, политиките или разпоредбите на
                мрежите, свързани с Услугата.
            </Paragraph>
            <Paragraph>
                Д. Умишлено или неумишлено да не нарушавате приложим местен, държавен,
                национален или международен закон.
                Нарушенията на нашите правила могат да доведат до премахване на Вашето
                съдържание от Услугата и/или до прекратяване на достъпа Ви.
            </Paragraph>
            <Paragraph>
                8. СЪОБЩЕНИЕ И ПРОЦЕДУРА ЗА ПРЕДЯВЯВАНЕ НА ИСКОВЕ ЗА
                НАРУШАВАНЕ НА АВТОРСКИ ПРАВА ИЛИ ИНТЕЛЕКТУАЛНА СОБСТВЕНОСТ
            </Paragraph>
            <Paragraph>
                „Кълчър бийт” ООД може при подходящи обстоятелства и по свое усмотрение да
                забрани и/или да прекрати достъпа на потребители, които нарушават правата на
                интелектуална собственост на други лица.
                Ако забележите нарушения на тези разпоредби, моля, докладвайте ни за тях на:
                culturebeatevents@gmail.com
            </Paragraph>
            <Paragraph>
                ДРУГИ
            </Paragraph>
            <Paragraph>
                23. „Кълчър бийт” ООД попада в обхвата на помирителната комисия при Комисия за
                защита на потребителите като орган за алтернативно решаване на спорове - kzp.bg.
            </Paragraph>
            <Paragraph>
                24. 1. Електронната връзка към Европейската платформа за онлайн решаване на
                спорове е, която може да бъде използвана от потребители е
                https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=BG
            </Paragraph>
            <Paragraph>
                24.2. Ползвателите на сайта www.culturebeatclub.com нямат право да разпространяват,
                променят (редактират), копират (освен за нетърговски, лични цели), предават, излагат,
                възпроизвеждат, репродуцират, публикуват, създават вторични материали, прехвърлят,
                продават или по какъвто и да е друг начин използват съдържание на приложението без
                изричното писмено разрешение „Кълчър бийт” ООД.
            </Paragraph>
            <Paragraph>
                24.3. Забранено е използването на приложението и е-мейл адресите на „Кълчър бийт”
                ООД за изпращане или предаване на каквито и да са материали с противозаконно,
                заплашително, невярно, подвеждащо, оскърбително, малтретиращо, опозоряващо,
                оклеветяващо, вулгарно, неприлично, скандално, подстрекаващо, порнографско или
                религиозно некоректно съдържание или каквито и да са материали, които установяват
                или окуражават поведение, което би било сметнато за криминално престъпление, би
                довело до гражданска или наказателна отговорност, или по друг начин би нарушило
                закона.
            </Paragraph>
            <Paragraph>
                24. 4. Забранено е използването на сайта с цел реклама или всяка друга форма на
                привличане на клиенти без писмено разрешение на „Кълчър бийт” ООД.
            </Paragraph>
            <Paragraph>
                25. Доставчикът не носи отговорност:
            </Paragraph>
            <Paragraph>
                -за действия, извършени чрез или върху сайта от Ползватели включително и
                посредством неоторизиран достъп до нея.
            </Paragraph>
            <Paragraph>
                - за претърпените вреди и пропуснати ползи, произтекли във връзка с използването,
                достъпа, съдържанието или достоверността на информацията на други приложения,
                интернет страници и ресурси, към които Доставчикът осигурява хипер-връзки
                посредством сайта.
            </Paragraph>
            <Paragraph>
                - за преки, непреки, случайни, последващи щети, възникнали поради невъзможността
                да се ползва приложението или части от него поради технически причини.
            </Paragraph>
            <Paragraph>
                26. Всички авторски права, търговски марки, дизайни, права върху бази данни и други
                обекти на интелектуалната собственост са притежание на техните законни собственици.
            </Paragraph>
            <Paragraph>
                27. В случай на противоречие между тези общи условия и уговорки в специален
                договор между Доставчика в сайта www.culturebeatclub.com и Ползвателя, с
                предимство се прилагат клаузите на специалния договор.
            </Paragraph>
            <Paragraph>
                28. Евентуалната недействителност на някоя от разпоредбите на тези общи условия
                няма да води до недействителност на целия договор.
            </Paragraph>
            <Paragraph>
                29. За неуредените в този договор въпроси, свързани с изпълнението и тълкуването на
                този договор, се прилагат законите на Република България.
            </Paragraph>
            <Paragraph>
                Приложение № 1 - Стандартен формуляр за упражняване правото на отказ от
                договора
            </Paragraph>
            <Paragraph>
                Стандартен формуляр за упражняване правото на отказ от договора:
            </Paragraph>
            <Paragraph>
                (попълнете и изпратете настоящия формуляр единствено ако желаете да се откажете от
                договора)
            </Paragraph>
            <Paragraph>
                – До („Кълчър бийт” ООД, гр. София, пл. България 1, НДК, ет 2, e-mail
                culturebeatevents@gmail.com):
            </Paragraph>
            <Paragraph>
                – С настоящото уведомявам/уведомяваме*, че се отказвам/отказваме* от сключения от
                мен/нас* договор за покупка на следните стоки*/за предоставяне на следната услуга*
            </Paragraph>
            <Paragraph>
                – Поръчано на*/получено на*
            </Paragraph>
            <Paragraph>
                – Име на потребителя/ите
            </Paragraph>
            <Paragraph>
                – Адрес на потребителя/ите
            </Paragraph>
            <Paragraph>
                – Подпис на потребителя/ите (само в случай, че настоящият формуляр е на хартия)
            </Paragraph>
            <Paragraph>
                – Дата
            </Paragraph>
            <Paragraph>
                ------------------------------------------------------
            </Paragraph>
            <Paragraph>
                * Ненужното се зачертава.
            </Paragraph>
            <Paragraph>
                Приложение № 2 - Информация относно упражняване правото на отказ от
                договора
            </Paragraph>
            <Paragraph>
                Информация относно упражняване правото на отказ от договора
            </Paragraph>
            <Paragraph>
                І. Стандартни указания за отказ:
            </Paragraph>
            <Paragraph>
                1. Право на отказ от договора от разстояние или извън търговския обект.
            </Paragraph>
            <Paragraph>
                2. Имате право да се откажете от настоящия договор, без да посочвате причини за
                това, в срок 14 дни.
            </Paragraph>
            <Paragraph>
                3. Срокът за отказ е 14 дни считано от датата, на която Вие сте направили
                резервация посредством сайта www.culturebeatclub.com.
            </Paragraph>
            <Paragraph>
                4. За да упражните правото си на отказ, трябва да ни уведомите на контактните
                данни, посочени на сайта www.culturebeatclub.com и за решението си да се
                откажете от договора с недвусмислено заявление (например писмо, изпратено по
                пощата или на електронна поща).
            </Paragraph>
            <Paragraph>
                5. Можете да използвате приложения стандартен формуляр за отказ, но това не е
                задължително. Можете също така да попълните и подадете по електронен път
                стандартния формуляр за отказ или друго недвусмислено заявление. Ако
                използвате тази възможност, ние незабавно ще Ви изпратим на траен носител
                (например по електронна поща) съобщение за потвърждение на получаването на
                отказа.
            </Paragraph>
            <Paragraph>
                6. За да спазите срока за отказ от договора, е достатъчно да изпратите съобщението
                си относно упражняването на право на отказ преди изтичането на срока за отказ
                от договора.
            </Paragraph>
            <Paragraph>
                ІІ.Действие на отказа.
            </Paragraph>
            <Paragraph>
                1. Ако се откажете от настоящия договор, ние ще Ви възстановим всички
                плащания, които сме получили от Вас без неоправдано забавяне и във всички
                случаи не по-късно от 14 дни считано от датата, на която ни информирате за
                Вашето решение за отказ от настоящия договор. Ще извършим възстановяването
                по посочена от вас банкова сметка; във всеки случай това възстановяване няма
                да бъде свързано с никакви разходи за Вас.
            </Paragraph>
            <BackLink>
                <FontAwesomeIcon size="lg" icon={faChevronLeft}/>
                <Link to="/"><Trans>Back</Trans></Link>
            </BackLink>
        </Wrapper>
    );
}

export default withTranslation()(GalleryPage);